import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginStyles } from '../stylsheet';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate(); // For redirecting to another page
  const [error, setError] = useState(null);

  // Handles the user clicking the login button
  const handleLogin = async (e) => {
    e.preventDefault();

    const query = `
      mutation Login($email: String!, $password: String!) {
        login(email: $email, password: $password) {
          session_uid
        }
      }
    `;

    const variables = {
      email,
      password,
    };

    try {
      const response = await fetch('https://dev.nc40.com/query', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query,
          variables,
        }),
      });

      const data = await response.json();

      // Check if the response contains errors
      if (data.errors) {
        console.error('Login failed: ', data.errors);
        setError(data.errors[0].message); // Set the error message to display
      } else {
        const sessionUid = data.data.login.session_uid;
        console.log('Login successful: ', sessionUid);
        localStorage.setItem('session_uid', sessionUid); // Store session ID in localStorage
        navigate('/home'); // Redirect to the home page after successful login
      }
    } catch (e) {
      console.error('Login failed: ', e);
      setError('An unexpected error occurred during login.');
    }
  };

    return(
        <div style={loginStyles.container}>
            <div style={loginStyles.card}>
              <h1 style={loginStyles.title}>Welcome Back</h1>
              <form onSubmit={handleLogin} style={loginStyles.form}>
                  <div style={loginStyles.inputGroup}>
                    <label>Email</label>
                    <input 
                        type="email" 
                        value={email} 
                        onChange={(e) => setEmail(e.target.value)} 
                        placeholder="Enter email" 
                        style={loginStyles.input}
                        required
                    />
                  </div>
                  <div style={loginStyles.inputGroup}>
                    <label>Password</label>
                    <input 
                        type="password" 
                        value={password} 
                        onChange={(e) => setPassword(e.target.value)} 
                        placeholder="Enter password" 
                        style={loginStyles.input}
                        required
                    />
                  </div>
                  {error && <div style={loginStyles.error}>{error}</div>}
                  <button type="submit" style={loginStyles.button}>
                      Login
                  </button>
              </form>
            </div>
        </div>
    )
}

export default Login;