import React from 'react';
import { useState, useEffect } from 'react';
import {Modal, Button } from 'react-bootstrap';
import backendClient from '../BackendClient';
import { CREATE_CLIENT_MUTATION } from '../graphql/mutations';
import { GET_CLIENTS } from '../graphql/queries';

const Clients = () => {
    
    const [ clientName, setClientName ] = useState('');
    const [ showCreateClient, setShowCreateClient ] = useState(false);
    const [ clientData, setClientData ] = useState([]);
    const [ error, setError ] = useState('');

    const handleClose = () => setShowCreateClient(false);
    const handleShow = () => setShowCreateClient(true);

    const sessionId = localStorage.getItem('session_uid');
   
    const headers = {
        'Content-Type': 'application/json', 
        'session-uid': sessionId
    }

    const handleSaveChanges = async () => {
        try {
            const response = await fetch(backendClient, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'session-uid': sessionId,
                },
                body: JSON.stringify({
                    query: CREATE_CLIENT_MUTATION,
                    variables: {
                        name: clientName,
                    },
                }),
            });
    
            const result = await response.json();
    
            if (response.ok) {
                console.log('Client created successfully:', result.data.createClient);
                
                fetchClients();

                handleClose();

            } else {
                console.error('Error creating client:', result.errors);
            }
        } catch (error) {
            console.error('Error creating client: ', error);
        }
    };

    
    const fetchClients = async () => {
        try {
        const response = await fetch(backendClient, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            'session-uid': sessionId,
            },
            body: JSON.stringify({
            query: GET_CLIENTS,
            }),
        });

        const result = await response.json();

        if (response.ok) {
            setClientData(result.data.clients);
        } else {
            setError(result.errors);
        }
        } catch (err) {
        setError(err.message);
        }
    };

    // call fetchClients when component mounts
    useEffect(() => {
        fetchClients();
    }, []);

    return (
        <div className="container">
            <br></br>
            <h4>Client Master List</h4>
            <br></br>
            <hr></hr>
            <br></br>
            <div className="d-flex">
                
                <select className="form-select me-2" style={{ width: '150px' }}>
                    <option defaultValue>Action</option>
                    <option value="delete">delete selected</option>
                </select>
                <input className="form-control" style={{ width: '350px' }} placeholder="Search..."/>

                <button className="btn btn-dark ms-auto" type="button" onClick={handleShow}>
                    New Client
                </button>
            </div>
            <br></br>

            <div className='table table-bordered'>
                <thead>
                    <tr>
                        <th>Client Name</th>
                        <th>Client UID</th>
                    </tr>
                </thead>
                <tbody>
                    {clientData.map((client) => (
                        <tr key={client.uid}>
                            <td>{client.name}</td>
                            <td>{client.uid}</td>
                        </tr>
                    ))
                    }
                </tbody>
            </div>

            {/* Modal */}
            <Modal show={showCreateClient} onHide={handleClose} size='lg'>
                <Modal.Header closeButton>
                <Modal.Title>New Client Entry Form</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex">
                        <div className="me-3">
                            <label for='clientname'>Client Name</label>
                            <input className="form-control" style={{width: '300px'}} id='clientname' value={clientName} onChange={(e) => setClientName(e.target.value)}></input>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="dark" onClick={handleSaveChanges}>
                    Save Changes
                </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Clients;