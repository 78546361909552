import React, { useEffect } from "react";
import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { CREATE_CUSTOMER } from "../graphql/mutations";
import backendClient from "../BackendClient";
import { GET_CUSTOMERS, GET_USERS } from "../graphql/queries";
import { EDIT_CUSTOMER } from "../graphql/mutations";
import { FaEdit, FaTrash } from 'react-icons/fa';
import { tableStyles, pageMargin } from "../stylsheet";

const Customers = () => {

    const [ customerUID, setCustomerUID ] = useState();
    const [ userData, setUserData ] = useState([]);
    const [ customerData, setCustomerData ] = useState([]);
    const [ customerName, setCustomerName ] = useState('');
    const [ addressUID, setAddressUID ] = useState();
    const [ street1, setStreet1 ] = useState();
    const [ street2, setStreet2 ] = useState();
    const [ city, setCity ] = useState();
    const [ state, setState ] = useState();
    const [ country, setCountry ] = useState();
    const [ zipcode, setZipcode ] = useState();
    const [ showCreateCustomer, setShowCreateCustomer ] = useState(false);
    const handleClose = () => setShowCreateCustomer(false);
    const handleShow = () => setShowCreateCustomer(true);

    

    const sessionId = localStorage.getItem('session_uid');

    
    const fetchCustomers = async () => {
        try {
            const response = await fetch(backendClient, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'session-uid': sessionId,
                },
                body: JSON.stringify({
                    query: GET_CUSTOMERS,
                }),
            });

            const result = await response.json();
            console.log(result);

            if(response.ok) {
                setCustomerData(result.data.customers);
            }
        }

        catch (error) {
            console.error("Error fetching customer data: ", error)
        }
    }

    const handleSaveChanges = async () => {
        try {
            const response = await fetch(backendClient, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'session-uid': sessionId,
                },
                body: JSON.stringify({
                    query: CREATE_CUSTOMER,
                    variables: {
                        name: customerName,
                        address: {
                            street1: street1,
                            street2: street2,
                            city: city,
                            state: state,
                            country: country,
                            zip: zipcode,
                        }
                    },
                }),
            });
    
            const result = await response.json();
    
            if (response.ok) {
                console.log('Customer created successfully: ', result.data);
                
                fetchCustomers();

                handleClose();

            } else {
                console.error('Error creating customer: ', result.errors);
            }
        } catch (error) {
            console.error('Error creating customer: ', error);
        }
    }

    // edit modal states
    const [ showEditModal, setShowEditModal ] = useState(false);

    // Handle edit action for a selected customer
    const handleEditOpen = (customer) => {
        setCustomerUID(customer.uid);
        setCustomerName(customer.name);
        setAddressUID(customer.address?.uid);
        setStreet1(customer.address.street1);
        setStreet2(customer.address.street2);
        setCity(customer.address.city);
        setState(customer.address.state);
        setCountry(customer.address.country);
        setZipcode(customer.address.zip);
        setShowEditModal(true);
        console.log('Edit customer: ', customer);
    };
    
    const handleEditClose = () => {
        setCustomerUID();
        setCustomerName();
        setAddressUID();
        setStreet1();
        setStreet2();
        setCity();
        setState();
        setCountry();
        setZipcode();
        setShowEditModal(false);
    };

    // Handle edit save customer
    const handleEditSave = async () => {    
        try {
            const response = await fetch(backendClient, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'session-uid': sessionId,
                },
                body: JSON.stringify({
                    query: EDIT_CUSTOMER,
                    variables: {
                      customer: {
                        uid: customerUID,
                        name: customerName,
                        address: {
                            uid: addressUID,
                            street1: street1,
                            street2: street2,
                            city: city,
                            state: state,
                            country: country,
                            zip: zipcode,
                        },
                      },
                    },
                  }),
            });
    
            const result = await response.json();
    
            if (response.ok) {
                console.log('Customer updated successfully: ', JSON.stringify(result.data));
                fetchCustomers();
                handleEditClose();
            } else {
                console.error('Error updating customer: ', JSON.stringify(result.errors));
            }
        } catch (error) {
            console.error('Error updating customer: ', JSON.stringify(error));
        }
    };
    
    const handleDelete = (uid) => {
        console.log('Delete customer:', uid);
    };

    useEffect(() => {
        fetchCustomers();
    }, []);
    
    //debugging use effect
    useEffect(() => {
        console.log("Address UID: ", addressUID)
    }, [addressUID])

    return (
        <div style={pageMargin.container}>
            <br></br>
            <h4>Customer Master List</h4>
            <br></br>
            <hr></hr>
            <br></br>
            <div className="d-flex">
                <input className="form-control" style={{ width: '350px' }} placeholder="Search..."/>
                <button className="btn btn-dark ms-auto" type="button" onClick={handleShow}>
                    New Customer
                </button>
            </div>
            <br></br>
            <div style={tableStyles.container}>
                <table className="table table-hover" style={tableStyles.table}>
                    <thead style={tableStyles.header}>
                        <tr>
                            <th style={tableStyles.headerCell}>Customer Name</th>
                            <th style={tableStyles.headerCell}>Street 1</th>
                            <th style={tableStyles.headerCell}>Street 2</th>
                            <th style={tableStyles.headerCell}>City</th>
                            <th style={tableStyles.headerCell}>State</th>
                            <th style={tableStyles.headerCell}>Country</th>
                            <th style={tableStyles.headerCell}>Zip</th>
                            <th style={{...tableStyles.headerCell, width: '150px'}}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {customerData.map((customer) => (
                            <tr key={customer.uid}>
                                <td style={tableStyles.cell}>{customer.name}</td>
                                <td style={tableStyles.cell}>{customer.address.street1}</td>
                                <td style={tableStyles.cell}>{customer.address.street2}</td>
                                <td style={tableStyles.cell}>{customer.address.city}</td>
                                <td style={tableStyles.cell}>{customer.address.state}</td>
                                <td style={tableStyles.cell}>{customer.address.country}</td>
                                <td style={tableStyles.cell}>{customer.address.zip}</td>
                                <td style={tableStyles.cell}>
                                    <div className="d-flex justify-content-center gap-2">
                                        <button 
                                            className="btn btn-sm"
                                            onClick={() => handleEditOpen(customer)}
                                            style={tableStyles.actionButton}
                                        >
                                            <FaEdit/> Edit
                                        </button>
                                        <button 
                                            className="btn btn-sm"
                                            onClick={() => handleDelete(customer.uid)}
                                            style={tableStyles.actionButton}
                                        >
                                            <FaTrash/> Delete
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Modal */}
            <Modal show={showCreateCustomer} onHide={handleClose} size='lg'>
                <Modal.Header closeButton>
                <Modal.Title>New Customer Entry Form</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col">
                            <label htmlfor='customername'>Customer Name</label>
                            <input className="form-control" id='customername' value={customerName} onChange={(e) => setCustomerName(e.target.value)}></input>
                        </div>
                        <div className="col">
                            <label htmlFor='street1'>Street 1</label>
                            <input className="form-control" id="street1" value={street1} onChange={(e) => setStreet1(e.target.value)}></input>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label htmlFor="street2">Street 2</label>
                            <input className="form-control" id="street2" value={street2} onChange={(e) => setStreet2(e.target.value)}></input>
                        </div>
                        <div className="col">
                            <label htmlFor="city">City</label>
                            <input className="form-control" id="city" value={city} onChange={(e) => setCity(e.target.value)}></input>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label htmlFor="state">State</label>
                            <input className="form-control" id="state" value={state} onChange={(e) => setState(e.target.value)}></input>
                        </div>
                        <div className="col">
                            <label htmlFor="country" id="country">Country</label>
                            <input className="form-control" id="country" value={country} onChange={(e) => setCountry(e.target.value)}></input>
                        </div>
                        <div className="col">
                            <label htmlFor="zipcode">Zipcode</label>
                            <input className="form-control" id="zipcode" value={zipcode} onChange={(e) => setZipcode(e.target.value)}></input>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="dark" onClick={handleSaveChanges}>
                    Save Changes
                </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal to edit customer name and address */}
            <Modal show={showEditModal} onHide={handleEditClose} size='lg'>
                <Modal.Header closeButton>
                <Modal.Title>Edit Customer Entry Form</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col">
                            <label htmlFor='customername'>Customer Name</label>
                            <input className="form-control" id='customername' value={customerName} onChange={(e) => setCustomerName(e.target.value)}></input>
                        </div>
                        <div className="col">
                            <label htmlFor='street1'>Street 1</label>
                            <input className="form-control" id="street1" value={street1} onChange={(e) => setStreet1(e.target.value)}></input>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label htmlFor="street2">Street 2</label>
                            <input className="form-control" id="street2" value={street2} onChange={(e) => setStreet2(e.target.value)}></input>
                        </div>
                        <div className="col">
                            <label htmlFor="city">City</label>
                            <input className="form-control" id="city" value={city} onChange={(e) => setCity(e.target.value)}></input>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label htmlFor="state">State</label>
                            <input className="form-control" id="state" value={state} onChange={(e) => setState(e.target.value)}></input>
                        </div>
                        <div className="col">
                            <label htmlFor="country" id="country">Country</label>
                            <input className="form-control" id="country" value={country} onChange={(e) => setCountry(e.target.value)}></input>
                        </div>
                        <div className="col">
                            <label htmlFor="zipcode">Zipcode</label>
                            <input className="form-control" id="zipcode" value={zipcode} onChange={(e) => setZipcode(e.target.value)}></input>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleEditClose}>
                    Close
                </Button>
                <Button variant="dark" onClick={handleEditSave}>
                    Save Changes
                </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Customers;