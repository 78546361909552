import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LaborForecastChart from "../Charts/LaborForecastChart";
import { tableStyles, pageMargin } from "../stylsheet";
import backendClient from "../BackendClient";

const TaskDetail = () => {
    const { taskUID } = useParams();
    const [forecastData, setForecastData] = useState([]);
    const [chartUpdateTrigger, setChartUpdateTrigger] = useState(false); // New state for chart refresh

    // Fetch labor forecast data
    const fetchLaborForecast = async () => {
        try {
            const response = await fetch(backendClient, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'session-uid': localStorage.getItem('session_uid'),
                },
                body: JSON.stringify({
                    query: `
                        query Labor_forecast_report($taskUid: String!) {
                            labor_forecast_report(task_uid: $taskUid) {
                                category_detail_uid
                                forecast {
                                    end_date
                                    hours
                                    start_date
                                    uid
                                }
                                user {
                                    uid
                                    first_name
                                    last_name
                                }
                            }
                        }
                    `,
                    variables: { taskUid: taskUID },
                }),
            });

            const result = await response.json();

            if (response.ok) {
                const sortedData = result.data.labor_forecast_report
                    .map(report => ({
                        ...report,
                        forecast: report.forecast.sort((a, b) => new Date(a.end_date) - new Date(b.end_date)),
                    }))
                    .sort((a, b) =>
                        `${a.user.first_name} ${a.user.last_name}`.localeCompare(
                            `${b.user.first_name} ${b.user.last_name}`
                        )
                    );

                setForecastData(sortedData || []);
            } else {
                console.error("Error fetching forecast data: ", result.errors);
            }
        } catch (error) {
            console.error("Error fetching forecast data: ", error);
        }
    };

    // Edit labor forecast mutation
    const editLaborForecast = async (forecast) => {
        try {
            const response = await fetch(backendClient, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'session-uid': localStorage.getItem('session_uid'),
                },
                body: JSON.stringify({
                    query: `
                        mutation Edit_labor_forecast($forecast: LaborForecastEdit!) {
                            edit_labor_forecast(forecast: $forecast) {
                                uid
                                hours
                            }
                        }
                    `,
                    variables: { forecast },
                }),
            });

            const result = await response.json();

            if (response.ok) {
                console.log("Forecast updated successfully: ", result.data.edit_labor_forecast);
            } else {
                console.error("Error updating forecast: ", result.errors);
            }
        } catch (error) {
            console.error("Error updating forecast: ", error);
        }
    };

    const handleUpdate = () => {
        const updatedForecasts = [];

        // Collect all modified forecasts
        forecastData.forEach((data) => {
            data.forecast.forEach((forecast) => {
                if (forecast.modified) {
                    updatedForecasts.push({
                        uid: forecast.uid,
                        hours: parseFloat(forecast.hours),
                    });
                }
            });
        });

        // Update all modified forecasts
        updatedForecasts.forEach((forecast) => editLaborForecast(forecast));

        // After update, reset modification flags and trigger chart update
        setForecastData((prevData) =>
            prevData.map((data) => ({
                ...data,
                forecast: data.forecast.map((forecast) => ({
                    ...forecast,
                    modified: false, // Reset modified flag
                })),
            }))
        );

        // Trigger chart re-render
        setChartUpdateTrigger((prev) => !prev);
    };

    // Handle hours change for inputs
    const handleHoursChange = (dataIndex, forecastIndex, value) => {
        const updatedData = [...forecastData];
        const forecast = updatedData[dataIndex].forecast[forecastIndex];

        forecast.hours = value;
        forecast.modified = true; // Mark as modified

        setForecastData(updatedData);
    };

    useEffect(() => {
        fetchLaborForecast();
    }, []);

    return (
        <div style={pageMargin.container}>
            <h1>Labor Forecast</h1>
            {/* Pass chartUpdateTrigger to force the chart to re-render */}
            <LaborForecastChart taskuid={taskUID} chartUpdateTrigger={chartUpdateTrigger} />
            <br></br>
            <br></br>
            
            {/* Update Button above the table */}
            <div style={{ marginBottom: "20px" }}>
                <button onClick={handleUpdate} className="btn btn-dark">
                    Update Forecast
                </button>
            </div>
            
            <div style={tableStyles.container}>
                <table className='table table-hover' style={tableStyles.table}>
                    <thead style={tableStyles.header}>
                        <tr>
                            <th style={tableStyles.headerCell}>User</th>
                            <th style={tableStyles.headerCell}>LCAT</th>
                            {forecastData.length > 0 &&
                                forecastData[0].forecast.map((forecast, index) => (
                                    <th key={index} style={tableStyles.headerCell}>
                                        {forecast.end_date}
                                    </th>
                                ))}
                        </tr>
                    </thead>
                    <tbody>
                        {forecastData.map((data, dataIndex) => (
                            <tr key={dataIndex}>
                                <td>{data.user.first_name} {data.user.last_name}</td>
                                <td>{data.category_detail_uid}</td>
                                {data.forecast.map((forecast, forecastIndex) => (
                                    <td key={forecastIndex}>
                                        <input
                                            type="number"
                                            value={forecast.hours}
                                            onChange={(e) =>
                                                handleHoursChange(dataIndex, forecastIndex, e.target.value)
                                            }
                                            style={{ width: "80px" }}
                                        />
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default TaskDetail;