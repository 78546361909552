import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import backendClient from "../BackendClient";
import { GET_ALL_SINGLE_PROJECT_DATA } from "../graphql/queries";
import { EDIT_LCAT_DETAIL, EDIT_TASK } from "../graphql/mutations";
import { CREATE_TASK } from "../graphql/mutations";
import { Modal, Button } from 'react-bootstrap';
import { CREATE_LCAT_DETAIL } from "../graphql/mutations";
import { formatCurrency } from "./utility";
import { FaEdit, FaTrash } from "react-icons/fa";
import { tableStyles, pageMargin } from "../stylsheet";


const PhaseDetail = () => {

    //#region Use States
    //project data use states
    const [ projectData, setProjectData ] = useState([]);

    //phase data use states
    const [ phaseData, setPhaseData ] = useState([]);

    //task data use states
    const [ taskData, setTaskData ] = useState([]);

    //LCAT data use states
    const [ lcatData, setLcatData ] = useState([]);
    const [ lcatDetailsData, setLcatDetailsData ] = useState([]);
    //#endregion

    //#region LCAT modal form states
    const [ budgetedHours, setBudgetedHours ] = useState();
    const [ fundedHours, setFundedHours ] = useState();
    const [ hourlyRate, setHourlyRate ] = useState();
    const [ lcatUid, setLcatUid ] = useState('');
    const [ showLCAT, setShowLCAT ] = useState(false);
    const handleShowLCAT = () => setShowLCAT(true);
    const handleCloseLCAT = () => {
        setShowLCAT(false);
        setBudgetedHours();
        setFundedHours();
        setHourlyRate();
        setLcatUid('');
    }
    //#endregion

    //#region Task modal form states
    const [ description, setDescription ] = useState();
    const [ startDate, setStartDate ] = useState();
    const [ endDate, setEndDate ] = useState();
    const [ fundedAmount, setFundedAmount ] = useState();
    const [ notToExceedAmount, setNotToExceedAmount ] = useState();
    const [ showTask, setShowTask ] = useState(false);
    const [ taskID, setTaskID ] = useState();
    const handleShowTask = () => setShowTask(true);
    const handleCloseTask = () => {
        setShowTask(false);
        setDescription();
        setStartDate();
        setEndDate();
        setFundedAmount();
        setNotToExceedAmount();
    }
    //#endregion

    //#region retrieve project and phase uid from url
    const { uid, phaseUID} = useParams();
    console.log(`Project UID: ${uid}, Phase UID: ${phaseUID}`);
    //#endregion

    //#region handle creating new phase lcat data
    const handleSaveLCAT = async () => {
        try {
            const response = await fetch(backendClient, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'session-uid': localStorage.getItem("session_uid"),
                },
                body: JSON.stringify({
                    query: CREATE_LCAT_DETAIL,
                    variables: {
                        cd: {
                            budgeted_hours: budgetedHours,
                            funded_hours: fundedHours,
                            hourly_rate: hourlyRate,
                            labor_category_uid: lcatUid,
                            phase_uid: phaseUID,
                        }
                    }
                })
            })

            const result = await response.json();

            if(response.ok && !result.errors) {
                console.log('LCAT details created successfully: ', result.data)
                handleCloseLCAT();
                fetchProjectData();
            } else {
                console.error('GraphQL Error: ', result.errors)
            }

        } catch (error) {
            console.error("Catastrophic error saving phase lcat data: ", error)
        }
    }
    //#endregion

    //#region handle creating new task data
    const handleSaveTask = async () => {
        try {
            const response = await fetch(backendClient, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'session-uid': localStorage.getItem("session_uid"),
                },
                body: JSON.stringify({
                    query: CREATE_TASK,
                    variables: {
                        task: {
                            description: description,
                            start_date: startDate,
                            end_date: endDate,
                            funded_amount: fundedAmount,
                            not_to_exceed_amount: notToExceedAmount,
                            phase_uid: phaseUID,
                            task_id: taskID,
                        }
                    }
                })
            })

            const result = await response.json();

            if(response.ok && !result.errors) {
                console.log('Task created successfully: ', result.data)
                handleCloseTask();
                fetchProjectData();
            } else {
                console.error('GraphQL Error: ', result.errors)
            }

        } catch (error) {
            console.error("Catastrophic error saving task data: ", error)
        }
    }
    //#endregion

    //#region fetch project data
    const fetchProjectData = async () => {
        try {
            const response = await fetch(backendClient, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'session-uid': localStorage.getItem('session_uid'),
                },
                body: JSON.stringify({
                    query: GET_ALL_SINGLE_PROJECT_DATA,
                    variables: {
                        filter: {
                            uids: uid
                        }
                    }
                })
            });

            const result = await response.json();
            
            if(response.ok) {
                const project = result.data.projects[0];
                const lcats = result.data.projects[0].labor_categories;
                const matchingPhase = project.phases.find(phase => phase.uid === phaseUID);
                const matchingTasks = matchingPhase.tasks;
                const matchingLcatDetails = matchingPhase.category_details;

                setProjectData(project);
                setLcatData(lcats);
                setPhaseData(matchingPhase);
                setTaskData(matchingTasks);
                setLcatDetailsData(matchingLcatDetails);

                console.log("Project Data: ", project);
                console.log("LCAT Names: ", lcats)
                console.log("Matching Phase Data: ", matchingPhase);
                console.log("Tasks Data: ", matchingTasks);
                console.log("LCAT Details: ", matchingLcatDetails)

            }

        } catch (error) {
            console.error("Catastrophic error fetching project data: ", error)
        }
    }

    useEffect(() => {
        fetchProjectData();
    }, [])
    //#endregion

    //edit lcat - functions and state variables
    const [ showEditLCAT, setShowEditLCAT ] = useState(false);
    const [ selectedLCAT, setSelectedLCAT ] = useState({});
    const handleEditLCAT = (lcatDetail) => {
        console.log('Editing LCAT: ', lcatDetail);
        setSelectedLCAT(lcatDetail);
        setShowEditLCAT(true);
    }

    //delete lcat - functions and state variables
    const handleDeleteLCAT = (lcatDetail) => {
        console.log('Deleting LCAT: ', lcatDetail);
    }

    //handle lcat edit close - function
    const handleCloseEditLCAT = () => {
        setShowEditLCAT(false);
        setSelectedLCAT({});
    }

    //handle lcat detail edit save - function
    const handleSaveEditLCAT = async () => {
        try {
            const response = await fetch(backendClient, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'session-uid': localStorage.getItem("session_uid"),
                },
                body: JSON.stringify({
                    query: EDIT_LCAT_DETAIL,
                    variables: {
                        cd: {
                            uid: selectedLCAT.uid,
                            budgeted_hours: selectedLCAT.budgeted_hours,
                            funded_hours: selectedLCAT.funded_hours,
                            hourly_rate: selectedLCAT.hourly_rate,
                        }
                    }
                })
            })

            const result = await response.json();

            if(response.ok && !result.errors) {
                console.log('LCAT details edited successfully: ', result.data)
                handleCloseEditLCAT();
                fetchProjectData();
            } else {
                console.error('GraphQL Error: ', result.errors)
            }
        } catch (error) {
            console.error("Catastrophic error editing lcat details: ", error)
        }
    }

    //edit task - functions and state variables
    const [ showEditTask, setShowEditTask ] = useState(false);
    const [ selectedTask, setSelectedTask ] = useState({});
    const handleEditTask = (task) => {
        console.log('Editing Task: ', task);
        setSelectedTask(task);
        setShowEditTask(true);
    }

    //delete task - functions and state variables
    const handleDeleteTask = (task) => {
        console.log('Deleting Task: ', task);
    }

    //handle task edit close - function
    const handleCloseEditTask = () => {
        setShowEditTask(false);
        setSelectedTask({});
    }

    //handle task edit save - function
    const handleSaveEditTask = async () => {
        try {
            const response = await fetch(backendClient, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'session-uid': localStorage.getItem("session_uid"),
                },
                body: JSON.stringify({
                    query: EDIT_TASK,
                    variables: {
                        task: {
                            uid: selectedTask.uid,
                            description: selectedTask.description,
                            start_date: selectedTask.start_date,
                            end_date: selectedTask.end_date,
                            funded_amount: selectedTask.funded_amount,
                            not_to_exceed_amount: selectedTask.not_to_exceed_amount,
                            task_id: selectedTask.task_id,
                        }
                    }
                })
            })

            const result = await response.json();

            if(response.ok && !result.errors) {
                console.log('Task edited successfully: ', result.data)
                handleCloseEditTask();
                fetchProjectData();
            } else {
                console.error('GraphQL Error: ', result.errors)
            }
        } catch (error) {
            console.error("Catastrophic error editing task: ", error)
        }
    }
    
    return (
        <div style={pageMargin.container}>
            <br></br>
            <br></br>
            <h5>Labor Category Details</h5>
            <div style={tableStyles.container}>
                <table className="table table-hover" style={tableStyles.table}>
                    <thead style={tableStyles.header}>
                        <tr
                            style={tableStyles.headerCell}
                        >
                            <th style={tableStyles.headerCell}>Labor Category</th>
                            <th style={tableStyles.headerCell}>Bill Rate</th>
                            <th style={tableStyles.headerCell}>Funded Hours</th>
                            <th style={tableStyles.headerCell}>Funded Value</th>
                            <th style={tableStyles.headerCell}>NTE Hours</th>
                            <th style={tableStyles.headerCell}>NTE Value</th>
                            <th style={{...tableStyles.headerCell, width: '150px'}}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            lcatDetailsData.map((lcatDetail) => {
                                const matchingLcat = lcatData.find(lcat => lcat.uid === lcatDetail.labor_category_uid)
                                const fundedValue = lcatDetail.hourly_rate * lcatDetail.funded_hours;
                                const nteValue = lcatDetail.hourly_rate * lcatDetail.budgeted_hours;

                                return (
                                    <tr key={lcatDetail.uid} style={tableStyles.row}>
                                        <td style={tableStyles.cell}>{matchingLcat ? matchingLcat.description : 'N/A'}</td>
                                        <td style={tableStyles.cell}>{lcatDetail.hourly_rate}</td>
                                        <td style={tableStyles.cell}>{lcatDetail.funded_hours}</td>
                                        <td style={tableStyles.cell}>{formatCurrency(fundedValue)}</td>
                                        <td style={tableStyles.cell}>{lcatDetail.budgeted_hours}</td>
                                        <td style={tableStyles.cell}>{formatCurrency(nteValue)}</td>
                                        <td style={tableStyles.cell}>
                                            <div className="d-flex justify-content-center gap-2">
                                                <button
                                                    className="btn btn-sm"
                                                    onClick={() => handleEditLCAT(lcatDetail)}
                                                    style={tableStyles.actionButton}
                                                >
                                                    <FaEdit/> Edit
                                                </button>
                                                <button
                                                    className="btn btn-sm"
                                                    onClick={() => handleDeleteLCAT(lcatDetail)}
                                                    style={tableStyles.actionButton}
                                                >
                                                    <FaTrash/> Delete
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
            <br></br>
            <div className="d-flex">
                <button className="btn btn-dark ms-auto" onClick={handleShowLCAT}>
                        New LCAT Details
                </button>
            </div>
            <br></br>
            <hr></hr>
            <br></br>
            <h5>Task Details</h5>
            <br></br>
            <div style={tableStyles.container}>
                <table className="table table-hover" style={tableStyles.table}>
                    <thead style={tableStyles.header}>
                        <tr>
                            <th style={tableStyles.headerCell}>Task ID</th>
                            <th style={tableStyles.headerCell}>Task Name</th>
                            <th style={tableStyles.headerCell}>Start Date</th>
                            <th style={tableStyles.headerCell}>End Date</th>
                            <th style={tableStyles.headerCell}>Funded</th>
                            <th style={tableStyles.headerCell}>Not to Exceed</th>
                            <th style={{...tableStyles.headerCell, width: '150px'}}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            taskData.map((task) => {
                                return(
                                    <tr key={task.uid} style={tableStyles.row}>
                                        <td style={tableStyles.cell}>{task.task_id}</td>
                                        <td style={tableStyles.cell}>
                                            <Link 
                                                to={`/projects/${uid}/phase/${phaseUID}/task/${task.uid}`}
                                                className="text-dark text-decoration-underlin"
                                            >
                                                {task.description} 
                                            </Link>
                                        </td>
                                        <td style={tableStyles.cell}>{task.start_date}</td>
                                        <td style={tableStyles.cell}>{task.end_date}</td>
                                        <td style={tableStyles.cell}>{task.funded_amount}</td>
                                        <td style={tableStyles.cell}>{task.not_to_exceed_amount}</td>
                                        <td style={tableStyles.cell}>
                                            <div className="d-flex justify-content-center gap-2">
                                                <button 
                                                    className="btn btn-sm"
                                                    onClick={() => handleEditTask(task)}
                                                    style={tableStyles.actionButton}
                                                >
                                                    <FaEdit/> Edit
                                                </button>
                                                <button 
                                                    className="btn btn-sm"
                                                    onClick={() => handleDeleteTask(task)}
                                                    style={tableStyles.actionButton}
                                                >
                                                    <FaTrash/> Delete
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
            <br></br>
            <div className="d-flex">
                <button className="btn btn-dark ms-auto" onClick={handleShowTask}>
                        New Task
                </button>
            </div>

            {/* Modal - New LCAT Details */}
            <Modal show={showLCAT} onHide={handleCloseLCAT} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Create LCAT Details for Current Phase</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <form>
                            <div className="row">
                                <div className="col">
                                    <label htmlFor="projectlcat">Project LCAT</label>
                                    <select className="form-control" id="projectlcat" value={lcatUid} onChange={(e) => setLcatUid(e.target.value)}>
                                        <option value="" disabled>Select Project LCAT</option>
                                        {
                                            lcatData.map(lcat => (
                                                <option key={lcat.uid} value={lcat.uid}>
                                                    {lcat.description}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="col">
                                    <label htmlFor="billrate">Bill Rate</label>
                                    <input className="form-control" id="billrate" value={hourlyRate} onChange={(e) => setHourlyRate(e.target.value)}></input>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <label htmlFor="fundedhours">Funded Hours</label>
                                    <input className="form-control" id="fundedhours" value={fundedHours} onChange={(e) => setFundedHours(e.target.value)}></input>
                                </div>
                                <div className="col">
                                    <label htmlFor="ntehours">NTE Hours</label>
                                    <input className="form-control" id="ntehours" value={budgetedHours} onChange={(e) => setBudgetedHours(e.target.value)}></input>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseLCAT}>
                        Close
                    </Button>
                    <Button variant="dark" onClick={handleSaveLCAT}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal - New Task */}
            <Modal show={showTask} onHide={handleCloseTask} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Create New Task for Current Phase</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <form>
                            <div className="row">
                                <div className="col">
                                    <label htmlFor="taskid">Task ID</label>
                                    <input className="form-control" id="taskid" value={taskID} onChange={(e) => setTaskID(e.target.value)}></input>
                                </div>
                                <div className="col">
                                    <label htmlFor="taskdescription">Task Description</label>
                                    <input className="form-control" id="taskdescription" value={description} onChange={(e) => setDescription(e.target.value)}></input>
                                </div>
                                <div className="col">
                                    <label htmlFor="startdate">Start Date</label>
                                    <input className="form-control" id="startdate" type='date' value={startDate} onChange={(e) => setStartDate(e.target.value)}></input>
                                </div>
                                <div className="col">
                                    <label htmlFor="enddate">End Date</label>
                                    <input className="form-control" id="enddate" type='date' value={endDate} onChange={(e) => setEndDate(e.target.value)}></input>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <label htmlFor="fundedamount">Funded Amount</label>
                                    <input className="form-control" id="fundedamount" value={fundedAmount} onChange={(e) => setFundedAmount(e.target.value)}></input>
                                </div>
                                <div className="col">
                                    <label htmlFor="nteamount">Not to Exceed Amount</label>
                                    <input className="form-control" id="nteamount" value={notToExceedAmount} onChange={(e) => setNotToExceedAmount(e.target.value)}></input>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseTask}>
                        Close
                    </Button>
                    <Button variant="dark" onClick={handleSaveTask}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal - Edit LCAT Details */}
            <Modal show={showEditLCAT} onHide={handleCloseEditLCAT} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Edit LCAT Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <form>
                            <div className="row">
                                <div className="col">
                                    <label htmlFor="projectlcat">Project LCAT</label>
                                    <select className="form-select" id="projectlcat" value={selectedLCAT.labor_category_uid} onChange={(e) => setSelectedLCAT({...selectedLCAT, labor_category_uid: e.target.value})}>
                                        <option value="" disabled>Select Project LCAT</option>
                                        {
                                            lcatData.map(lcat => (
                                                <option key={lcat.uid} value={lcat.uid}>
                                                    {lcat.description}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="col">
                                    <label htmlFor="billrate">Bill Rate</label>
                                    <input className="form-control" id="billrate" value={selectedLCAT.hourly_rate} onChange={(e) => setSelectedLCAT({...selectedLCAT, hourly_rate: e.target.value})}></input>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <label htmlFor="fundedhours">Funded Hours</label>
                                    <input className="form-control" id="fundedhours" value={selectedLCAT.funded_hours} onChange={(e) => setSelectedLCAT({...selectedLCAT, funded_hours: e.target.value})}></input>
                                </div>
                                <div className="col">
                                    <label htmlFor="ntehours">NTE Hours</label>
                                    <input className="form-control" id="ntehours" value={selectedLCAT.budgeted_hours} onChange={(e) => setSelectedLCAT({...selectedLCAT, budgeted_hours: e.target.value})}></input>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseEditLCAT}>
                        Close
                    </Button>
                    <Button variant="dark" onClick={handleSaveEditLCAT}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal - Edit Task */}
            <Modal show={showEditTask} onHide={handleCloseEditTask} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Edit Task</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <form>
                            <div className="row">
                                <div className="col">
                                    <label htmlFor="taskid">Task ID</label>
                                    <input className="form-control" id="taskid" value={selectedTask.task_id} onChange={(e) => setSelectedTask({...selectedTask, task_id: e.target.value})}></input>
                                </div>
                                <div className="col">
                                    <label htmlFor="taskdescription">Task Description</label>
                                    <input className="form-control" id="taskdescription" value={selectedTask.description} onChange={(e) => setSelectedTask({...selectedTask, description: e.target.value})}></input>
                                </div>
                                <div className="col">
                                    <label htmlFor="startdate">Start Date</label>
                                    <input className="form-control" id="startdate" type='date' value={selectedTask.start_date} onChange={(e) => setSelectedTask({...selectedTask, start_date: e.target.value})}></input>
                                </div>
                                <div className="col">
                                    <label htmlFor="enddate">End Date</label>
                                    <input className="form-control" id="enddate" type='date' value={selectedTask.end_date} onChange={(e) => setSelectedTask({...selectedTask, end_date: e.target.value})}></input>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <label htmlFor="fundedamount">Funded Amount</label>
                                    <input className="form-control" id="fundedamount" value={selectedTask.funded_amount} onChange={(e) => setSelectedTask({...selectedTask, funded_amount: e.target.value})}></input>
                                </div>
                                <div className="col">
                                    <label htmlFor="nteamount">Not to Exceed Amount</label>
                                    <input className="form-control" id="nteamount" value={selectedTask.not_to_exceed_amount} onChange={(e) => setSelectedTask({...selectedTask, not_to_exceed_amount: e.target.value})}></input>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseEditTask}>
                        Close
                    </Button>
                    <Button variant="dark" onClick={handleSaveEditTask}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default PhaseDetail;