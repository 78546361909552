import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
    const session_uid = localStorage.getItem('session_uid');

    if (!session_uid) {
        return <Navigate to='/' />
    }

    return children;
}

export default ProtectedRoute;