import React, { useEffect, useRef, useState } from "react";
import * as d3 from 'd3';
import backendClient from "../BackendClient";
import { GET_LABOR_HOURS } from "../graphql/queries";

const LaborForecastChart = ({ taskuid, chartUpdateTrigger }) => {
    const [laborHours, setLaborHours] = useState([]);
    const chartRef = useRef();

    const fetchLaborHours = async () => {
        try {
            const response = await fetch(backendClient, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'session-uid': localStorage.getItem('session_uid'),
                },
                body: JSON.stringify({
                    query: GET_LABOR_HOURS,
                    variables: { taskUid: taskuid },
                }),
            });

            const result = await response.json();
            console.log("Task UID:", taskuid);

            if (response.ok) {
                setLaborHours(result.data.labor_forecast_report_totals || []);
                console.log("Fetched labor hours: ", result.data.labor_forecast_report_totals);
            } else {
                console.error("Error fetching labor hours for charting: ", result.errors);
            }
        } catch (error) {
            console.error("Catastrophic fetching labor hours for charting: ", error);
        }
    };

    // Fetch labor hours on initial render and whenever taskuid or chartUpdateTrigger changes
    useEffect(() => {
        fetchLaborHours();
    }, [taskuid, chartUpdateTrigger]);  // Re-fetch data when chartUpdateTrigger changes

    useEffect(() => {
        if (!laborHours.length) return;
    
        const margin = { top: 20, right: 30, bottom: 30, left: 40 };
        const width = 800 - margin.left - margin.right;
        const height = 400 - margin.top - margin.bottom;
    
        const svg = d3.select(chartRef.current)
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom);
    
        svg.selectAll("*").remove(); // Clear previous chart content
    
        const g = svg.append("g")
            .attr("transform", `translate(${margin.left}, ${margin.top})`);
    
        // Parse date strings into Date objects and sort data by end_date
        const parseDate = d3.timeParse("%Y-%m-%d");
        const data = laborHours
            .map(d => ({
                ...d,
                end_date: parseDate(d.end_date),
            }))
            .sort((a, b) => a.end_date - b.end_date); // Sort by end_date
    
        // Define scales
        const x = d3.scaleTime()
            .domain(d3.extent(data, d => d.end_date))
            .range([0, width]);
    
        const y = d3.scaleLinear()
            .domain([0, d3.max(data, d => d.hours)])
            .range([height, 0]);
    
        // Add axes
        g.append("g")
            .attr("transform", `translate(0, ${height})`)
            .call(d3.axisBottom(x).ticks(6));
    
        g.append("g")
            .call(d3.axisLeft(y));
    
        // Define line generator
        const line = d3.line()
            .x(d => x(d.end_date))
            .y(d => y(d.hours))
            .curve(d3.curveMonotoneX); // Optional: Smooth the line
    
        // Add line path
        g.append("path")
            .datum(data)
            .attr("fill", "none")
            .attr("stroke", "steelblue")
            .attr("stroke-width", 2)
            .attr("d", line);
    
        // Add points
        g.selectAll("circle")
            .data(data)
            .enter()
            .append("circle")
            .attr("cx", d => x(d.end_date))
            .attr("cy", d => y(d.hours))
            .attr("r", 4)
            .attr("fill", "steelblue");
    }, [laborHours]); // Re-render chart when laborHours state changes

    return <svg ref={chartRef}></svg>;
};

export default LaborForecastChart;
