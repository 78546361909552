import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './Pages/Login';
import Home from './Pages/Home';
import Purchasing from './Pages/Purchasing';
import Layout from './Layout';
import Staff from './Pages/Staff';
import Vendors from './Pages/Vendors';
import Invoices from './Pages/Invoices';
import Projects from './Pages/Projects';
import ProtectedRoute from './ProtectedRoute';
import Clients from './Pages/Clients';
import Customers from './Pages/Customers';
import Users from './Pages/Users';
import ProjectDetail from './Pages/ProjectDetail';
import PhaseDetail from './Pages/PhaseDetail';
import TaskDetail from './Pages/TaskDetail';

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
            <Route path='/' element={<Login/>}></Route>
            <Route path='/home' element={<ProtectedRoute><Home/></ProtectedRoute>}></Route>
            <Route path='/purchasing' element={<ProtectedRoute><Purchasing/></ProtectedRoute>}></Route>
            <Route path='/staff' element={<ProtectedRoute><Staff/></ProtectedRoute>}></Route>
            <Route path='/vendors' element={<ProtectedRoute><Vendors/></ProtectedRoute>}></Route>
            <Route path='/invoices' element={<ProtectedRoute><Invoices/></ProtectedRoute>}></Route>
            <Route path='/projects' element={<ProtectedRoute><Projects/></ProtectedRoute>}></Route>
            <Route path='/customers' element={<ProtectedRoute><Customers/></ProtectedRoute>}></Route>
            <Route path='/clients' element={<ProtectedRoute><Clients></Clients></ProtectedRoute>}></Route>
            <Route path='/users' element={<ProtectedRoute><Users></Users></ProtectedRoute>}></Route>
            <Route path='/projects/:uid' element={<ProtectedRoute><ProjectDetail></ProjectDetail></ProtectedRoute>}></Route>
            <Route path='/projects/:uid/phase/:phaseUID' element={<ProtectedRoute><PhaseDetail></PhaseDetail></ProtectedRoute>}></Route>
            <Route path='/projects/:uid/phase/:phaseUID/task/:taskUID' element={<ProtectedRoute><TaskDetail></TaskDetail></ProtectedRoute>}></Route>
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;