export const tableStyles = {
    container: {
      background: '#fff',
      borderRadius: '12px',
      boxShadow: '0 -2px 4px rgba(0, 0, 0, 0.1), 0 4px 6px rgba(0, 0, 0, 0.1)',
      overflow: 'hidden',
      maxHeight: '800px',
      overflowY: 'auto',
    },
    table: {
      margin: 0
    },
    header: {
      background: 'linear-gradient(180deg, #2C3E50 0%, #1A252F 100%)',
      color: 'white', 
    },
    headerCell: {
      border: 'none',
      padding: '15px',
      fontWeight: 'bold',
      cursor: 'pointer',
      transition: 'all 0.3s ease'
    },
    row: {
      transition: 'all 0.2s ease'
    },
    cell: {
      padding: '12px 15px',
      verticalAlign: 'middle'
    },
    actionButton: {
      transition: 'all 0.2s ease',
      border: '1px solid #2C3E50',
      color: '#2C3E50',
      backgroundColor: 'transparent'
    }
  };

export const titleStyles = {
    container: {
      marginBottom: '2rem'
    },
    heading: {
      background: 'linear-gradient(90deg, #2C3E50 0%, #3498db 100%)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      fontSize: '2.5rem',
      fontWeight: '600',
      letterSpacing: '0.5px',
      paddingBottom: '0.5rem',
      borderBottom: '2px solid #ecf0f1',
      textShadow: '2px 2px 4px rgba(0,0,0,0.1)'
    }
  };

  export const loginStyles = {
    container: {
      minHeight: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'linear-gradient(135deg, #2C3E50 0%, #1A252F 100%)',
    },
    card: {
      background: 'white',
      borderRadius: '12px',
      padding: '2rem',
      width: '100%',
      maxWidth: '400px',
      boxShadow: '0 10px 25px rgba(0,0,0,0.2)',
    },
    title: {
      color: '#2C3E50',
      fontSize: '2rem',
      fontWeight: '600',
      marginBottom: '1.5rem',
      textAlign: 'center'
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem'
    },
    inputGroup: {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.5rem'
    },
    label: {
      color: '#2C3E50',
      fontSize: '0.9rem',
      fontWeight: '500'
    },
    input: {
      padding: '0.75rem',
      borderRadius: '8px',
      border: '2px solid #ecf0f1',
      transition: 'border-color 0.3s ease',
      outline: 'none',
      '&:focus': {
        borderColor: '#3498db'
      }
    },
    button: {
      padding: '0.75rem',
      borderRadius: '8px',
      border: 'none',
      background: 'linear-gradient(90deg, #2C3E50 0%, #3498db 100%)',
      color: 'white',
      fontWeight: '500',
      cursor: 'pointer',
      transition: 'transform 0.2s ease',
      '&:hover': {
        transform: 'translateY(-1px)'
      }
    },
    error: {
      color: '#e74c3c',
      fontSize: '0.9rem',
      marginTop: '0.5rem',
      textAlign: 'center'
    }
  };

  export const pageMargin = {
    container: {
      marginLeft: '200px',
    }
  }