

export const LOGIN_MUTATION = `
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
        session_uid
    }
  }
`;

export const CREATE_CLIENT_MUTATION = `
  mutation ($name: String!) {
      new_client(client: {name: $name}) {
          uid
          name
      }
  }
`;

export const CREATE_PROJECT_MUTATION = `
    mutation ($name: String!, $customer_uid: String!, $start_date: String!, $end_date: String!, $project_id: String!, $project_manager_uid: String!) {
    new_project(project: {name: $name, customer_uid: $customer_uid, start_date: $start_date, end_date: $end_date, project_id: $project_id, project_manager_uid: $project_manager_uid}) {
        uid
        name
        customer_uid
        start_date
        end_date
        project_manager_uid
        }
    }
  `

export const CREATE_USER = `
  mutation ($first_name: String!, $last_name: String!, $email: String!, $client_uid: String!) {
    new_user(user: {first_name: $first_name, last_name: $last_name, email: $email, client_uid: $client_uid}) {
      uid
      email
      first_name
      last_name
      client_uid
    }
  }
`

export const CREATE_VENDOR = `
  mutation ($name: String!, $client_uid: String!) {
    new_vendor(vendor: {name: $name, client_uid: $client_uid}) {
      uid
      name
      client_uid
    }
  }
`

export const CREATE_CUSTOMER = `
  mutation CreateCustomer($name: String!, $address: AddressInput!) {
    new_customer(customer: { name: $name, address: $address }) {
      uid
      name
      address {
        street1
        street2
        city
        state
        country
        zip
      }
    }
  }
`;

export const CREATE_PROJECT_PHASE = `
  mutation Mutation($phase: PhaseInput!) {
    new_phase(phase: $phase) {
      budget
      description
      end_date
      phase_id
      project_uid
      start_date
    }
  }
`

export const CREATE_LABOR_CATEGORY = `
  mutation New_labor_category($lcat: LaborCategoryInput!) {
    new_labor_category(lcat: $lcat) {
      description
      project_uid
      uid
    }
  }
`

export const CREATE_LCAT_DETAIL = `
  mutation Mutation($cd: CategoryDetailInput!) {
    new_category_detail(cd: $cd) {
      budgeted_hours
      funded_hours
      hourly_rate
      labor_category_uid
      phase_uid
    }
  }
`

export const CREATE_TASK = `
  mutation Mutation($task: TaskInput!) {
    new_task(task: $task) {
      description
      end_date
      funded_amount
      not_to_exceed_amount
      start_date
      phase_uid
      task_id
    }
  }
`

export const CREATE_LABOR_FORECAST = `
  mutation Mutation($forecast: LaborForecastInput!) {
    new_labor_forecast(forecast: $forecast) {
      user_uid
      task_uid
      start_date
      end_date
      category_detail_uid
      hours
    }
  }
`

export const CREATE_PURCHASE_ORDER = `
  mutation New_purchase_order($purchaseOrder: PurchaseOrderInput!) {
    new_purchase_order(purchase_order: $purchaseOrder) {
      id
      phase_uid
      vendor_uid
    }
  }
`

export const EDIT_PROJECT = `
  mutation Mutation($project: EditProject!) {
    edit_project(project: $project) {
      uid  
      customer_uid
      end_date
      name
      project_id
      project_manager_uid
      start_date
    }
  }
`

export const EDIT_LCAT = `
  mutation Edit_labor_category($lcat: EditLaborCategory!) {
    edit_labor_category(lcat: $lcat) {
      description
      project_uid
      uid
    }
  }
`

export const EDIT_PHASE = `
  mutation Mutation($phase: EditPhase!) {
    edit_phase(phase: $phase) {
      phase_id
      description
      start_date
      end_date
      budget
    }
  }
`

export const EDIT_LCAT_DETAIL = `
  mutation Edit_category_detail($cd: EditCategoryDetail!) {
    edit_category_detail(cd: $cd) {
      budgeted_hours
      funded_hours
      hourly_rate
      uid
    }
  }
`

export const EDIT_TASK = `
  mutation Edit_task($task: EditTask!) {
    edit_task(task: $task) {
      description
      end_date
      funded_amount
      not_to_exceed_amount
      start_date
      uid
      task_id
    }
  }
`

export const EDIT_CUSTOMER = `
  mutation Edit_customer($customer: EditCustomer!) {
    edit_customer(customer: $customer) {
      address {
        uid  
        street1
        street2
        state
        country
        city
        zip
      }
      name
      uid
    }
  }
`;

export const EDIT_USER = `
  mutation Edit_user($user: EditUser!) {
    edit_user(user: $user) {
      uid
      first_name
      last_name
      email
    }
  }
`