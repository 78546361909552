
export const GET_CLIENTS = `
    query {
        clients {
            uid
            name
        }
    }
`

export const GET_VENDORS = `
    query {
        vendors {
            uid
            name
        }
    }
`

export const GET_CUSTOMERS = `
    query Customers {
        customers {
            name
            uid
            address {
                uid
                city
                country
                state
                street1
                street2
                zip
            }
        }
    }
`

export const GET_USERS = `
    query Users{
        users {
            uid
            first_name
            last_name
            email
        }
    }
`

export const GET_PROJECTS = `
    query {
        projects {
            uid
            project_id
            name
            start_date
            end_date
            project_manager_uid
            customer_uid
        }
    }
`
export const GET_PHASES_BY_PROJECT_UID = `
    query Query($filter: ProjectsFilter) {
        projects(filter: $filter) {
            phases {
                uid
                budget
                description
                end_date
                phase_id
                start_date
            }
        }
    }
`

export const GET_SINGLE_PROJECT = `
    query Projects($filter: ProjectsFilter) {
        projects(filter: $filter) {
            project_id
            name
            customer_uid
            project_manager_uid
            start_date
            end_date
        }
    }
`


export const GET_LCAT_BY_PROJECT_UID = `
    query Query($filter: ProjectsFilter) {
        projects(filter: $filter) {
            labor_categories {
                description
                project_uid
                uid
            }
        }
    }
`

export const GET_ALL_SINGLE_PROJECT_DATA = `
    query Projects($filter: ProjectsFilter) {
        projects(filter: $filter) {
            labor_categories {
                description
                uid
            }
            customer_uid
            start_date
            end_date
            name
            project_manager_uid
            project_id
            uid
            phases {
                uid
                budget
                category_details {
                    budgeted_hours
                    funded_hours
                    hourly_rate
                    labor_category_uid
                    phase_uid
                    uid
                }
                description
                end_date
                phase_id
                project_uid
                start_date
                tasks {
                    uid    
                    description
                    end_date
                    funded_amount
                    not_to_exceed_amount
                    phase_uid
                    start_date
                    task_id
                } 
            }
        }
    }
`

export const GET_LABOR_FORECAST_REPORT = `
    query Labor_forecast_report($taskUid: String!) {
    labor_forecast_report(task_uid: $taskUid) {
        category_detail_uid
        forecast {
        end_date
        hours
        start_date
        uid
        }
        user {
        uid
        first_name
        last_name
        }
    }
}
`

export const GET_PURCHASE_ORDERS = `
    query {
        purchase_orders {
            id
            phase_uid
            vendor_uid
        }
    }
`

export const GET_LABOR_HOURS = `
    query Labor_forecast_report_totals($taskUid: String!) {
        labor_forecast_report_totals(task_uid: $taskUid) {
            hours
            end_date
        }
    }
`