import React from 'react';

const Home = () => {
    return (
        <div className='container'>
            <h2>Home</h2>
        </div>
    )
}

export default Home;