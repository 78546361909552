import React, { useState } from "react";
import {Modal, Button } from 'react-bootstrap';
import { GET_VENDORS } from "../graphql/queries";
import { useEffect } from "react";
import backendClient from "../BackendClient";
import { CREATE_VENDOR } from "../graphql/mutations";
import { clientUID } from "../clientUID";
import { FaEdit, FaTrash } from 'react-icons/fa';
import { tableStyles, pageMargin } from "../stylsheet";

const Vendors = () => {   
    
    const [ showCreateVendor, setShowCreateVendor ] = useState(false);
    const [ vendorData, setVendorData ] = useState([]);
    const [ vendorName, setVendorName ] = useState('');
    const [ error, setError ] = ('');
    const [ showEditVendor, setShowEditVendor ] = useState(false);
    
    const sessionId = localStorage.getItem('session_uid');

    //fetch vendor data for loading to master table
    const fetchVendors = async () => {
        try {
            const response = await fetch(backendClient, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'session-uid': localStorage.getItem('session_uid'),
                },
                body: JSON.stringify({
                    query: GET_VENDORS,
                }),
            });

            const result = await response.json();
            console.log(result)

            if (response.ok) {
                setVendorData(result.data.vendors); 
            } else {
                setError(result.errors);
            }
            
        }

        catch (err) {
        }
    }

    useEffect(() => {
        fetchVendors();
    }, []);

    //send form data to back end for vendor creation
    const handleSaveChanges = async () => {
        try {
            const response = await fetch(backendClient, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'session-uid': sessionId,
                },
                body: JSON.stringify({
                    query: CREATE_VENDOR,
                    variables: {
                        name: vendorName,
                        client_uid: clientUID,
                    },
                }),
            });
    
            const result = await response.json();
    
            if (response.ok) {
                console.log('Vendor created successfully:', result.data.createVendor);
                
                fetchVendors();

                handleClose();

            } else {
                console.error('Error creating vendor:', result.errors);
            }
        } catch (error) {
            console.error('Error creating vendor: ', error);
        }
    };

    //call function when component mounts
    useEffect(() => {
        fetchVendors();
    }, []);

    const handleClose = () => {
        setVendorName('');
        setShowCreateVendor(false);
    }

    const handleShow = () => {
        setShowCreateVendor(true);
    }

    const handleEdit = (vendor) => {
        setShowEditVendor(true);
        setVendorName(vendor.name);
    };

    const handleCloseEditVendor = () => {
        setVendorName('');
        setShowEditVendor(false);
    }
    
    const handleDelete = (uid) => {
        // TODO: Implement delete functionality
        console.log('Delete vendor:', uid);
    };

    return(

        <div style={pageMargin.container}>
            <br></br>
            <h4>Vendor Master List</h4>
            <br></br>
            <hr></hr>
            <br></br>
            <div className="d-flex">
                <input className="form-control" style={{ width: '350px' }} placeholder="Search..."/>
                <button className="btn btn-dark ms-auto" type="button" onClick={handleShow}>
                    New Vendor
                </button>
            </div>
            <br></br>

            {/*table for vendor data*/}
            <div className='table-responsive' style={tableStyles.container}>
                <table className="table table-hover" style={tableStyles.table}>
                    <thead style={tableStyles.tableCell}>
                        <tr>
                            <th style={tableStyles.headerCell}>Vendor Name</th>
                            <th style={tableStyles.headerCell}>Street</th>
                            <th style={tableStyles.headerCell}>City</th>
                            <th style={tableStyles.headerCell}>State</th>
                            <th style={tableStyles.headerCell}>Zip</th>
                            <th style={{...tableStyles.headerCell, width: '150px'}}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {vendorData.map((vendor) => (
                            <tr key={vendor.uid} style={tableStyles.cell}>
                                <td style={tableStyles.cell}>{vendor.name}</td>
                                <td style={tableStyles.cell}>{vendor.address}</td>
                                <td style={tableStyles.cell}>{vendor.city}</td>
                                <td style={tableStyles.cell}>{vendor.state}</td>
                                <td style={tableStyles.cell}>{vendor.zip}</td>
                                <td style={tableStyles.cell}>
                                    <div className="d-flex justify-content-center gap-2">
                                        <button 
                                            className="btn btn-sm"
                                            onClick={() => handleEdit(vendor)}
                                            style={tableStyles.actionButton}
                                        >
                                            <FaEdit/> Edit
                                        </button>
                                        <button 
                                            className="btn btn-sm"
                                            onClick={() => handleDelete(vendor.uid)}
                                            style={tableStyles.actionButton}
                                        >
                                            <FaTrash/> Delete
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>


            {/* Modal */}
            <Modal show={showCreateVendor} onHide={handleClose} size='lg'>
                <Modal.Header closeButton>
                <Modal.Title>New Vendor Entry</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex">
                        <div className="me-3">
                            <label for='vendorname'>Vendor Name</label>
                            <input className="form-control" style={{width: '300px'}} id='vendorname' value={vendorName} onChange={(e) => setVendorName(e.target.value)}></input>
                        </div>
                        <div className="col">
                            <label for='street'>Street</label>
                            <input className="form-control" id='street'></input>
                        </div>
                    </div>
                    <br></br>
                    <div className="row">
                        <div className="col">
                            <label for='city'>City</label>
                            <input className="form-control" id='city'></input>
                        </div>
                        <div className="col">
                            <label for='state'>State</label>
                            <input className="form-control" id='state'></input>
                        </div>
                        <div className="col">
                            <label for='zipcode'>Zipcode</label>
                            <input className="form-control" id='zipcode'></input>
                        </div>
                    </div>
                    <br></br>
                    <div className="row">
                        <div className="col">
                            <label for='primarycontact'>Primary Contact</label>
                            <input className="form-control" id='primarycontract'></input>
                        </div>
                        <div className="col">
                            <label for='contactemail'>Contact Email</label>
                            <input className="form-control" id='contactemail'></input>
                        </div>
                        <div className="col">
                            <label for='contactphone'>Contact Phone</label>
                            <input className="form-control" id='contactphone'></input>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="dark" onClick={handleSaveChanges}>
                    Save Changes
                </Button>
                </Modal.Footer>
            </Modal>

            {/* edit vendor modal */}
            <Modal show={showEditVendor} onHide={handleCloseEditVendor} size='lg'>
                <Modal.Header closeButton>
                <Modal.Title>Edit Vendor</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex">
                        <div className="me-3">
                            <label for='vendorname'>Vendor Name</label>
                            <input className="form-control" style={{width: '300px'}} id='vendorname' value={vendorName} onChange={(e) => setVendorName(e.target.value)}></input>
                        </div>
                        <div className="col">
                            <label for='street'>Street</label>
                            <input className="form-control" id='street'></input>
                        </div>
                    </div>
                    <br></br>
                    <div className="row">
                        <div className="col">
                            <label for='city'>City</label>
                            <input className="form-control" id='city'></input>
                        </div>
                        <div className="col">
                            <label for='state'>State</label>
                            <input className="form-control" id='state'></input>
                        </div>
                        <div className="col">
                            <label for='zipcode'>Zipcode</label>
                            <input className="form-control" id='zipcode'></input>
                        </div>
                    </div>
                    <br></br>
                    <div className="row">
                        <div className="col">
                            <label for='primarycontact'>Primary Contact</label>
                            <input className="form-control" id='primarycontract'></input>
                        </div>
                        <div className="col">
                            <label for='contactemail'>Contact Email</label>
                            <input className="form-control" id='contactemail'></input>
                        </div>
                        <div className="col">
                            <label for='contactphone'>Contact Phone</label>
                            <input className="form-control" id='contactphone'></input>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseEditVendor}>
                    Close
                </Button>
                <Button variant="dark" onClick={handleSaveChanges}>
                    Save Changes
                </Button>
                </Modal.Footer>
            </Modal>
        </div>  
    )  
}

export default Vendors;