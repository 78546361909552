import React from "react";

const Staff = () => {

    return(
        <div className="container">
            <h2>Staff</h2>
        </div>
    )
}

export default Staff;