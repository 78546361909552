import React from "react";
import { pageMargin, tableStyles } from "../stylsheet";
import { useState } from "react";
import { GET_PURCHASE_ORDERS } from "../graphql/queries";
import backendClient from "../BackendClient";

const Purchasing = () => {
   
    // data states
    const [purchaseOrders, setPurchaseOrders] = useState([]);


    // fetch purchase orders
    const fetchPurchaseOrders = async() => {
        try {
            const response = await fetch(backendClient, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'session-uid': localStorage.getItem('session-uid'),
                },
                body: JSON.stringify({
                    query: GET_PURCHASE_ORDERS,
                }),
            });

            const result = await response.json();
            console.log("Fetched purchase orders: ", result)

            if(response.ok) {
                setPurchaseOrders(result.data.purchase_orders)
            } else {
                console.log(result.error)
            }
        } catch(error) {
            console.error("Error fetching purchase orders: ", error)
        }
    };
    
    return(
        <div style={pageMargin.container}>
            <div className="col">
                <h2>Purchasing</h2>
            </div>
            <br></br>
            <div style={tableStyles.container}>
                <table className="table table-hover">
                    <thead style={tableStyles.header}>
                        <tr>
                            <th style={tableStyles.headerCell}>PO ID</th>
                            <th style={tableStyles.headerCell}>Vendor</th>
                            <th style={tableStyles.headerCell}>Project</th>
                            <th style={tableStyles.headerCell}>Phase</th>
                            <th style={tableStyles.headerCell}>Total Extended Value</th>
                        </tr>
                    </thead>
                </table>  
            </div>
        </div>
    )
}

export default Purchasing;