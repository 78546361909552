import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { FaHome, FaUsers, FaStore, FaShoppingCart, FaFileInvoiceDollar, FaProjectDiagram, FaUserFriends, FaUserCog, FaSignOutAlt } from 'react-icons/fa';

const Sidebar = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('session_uid');
    navigate('/');
  }
  
  return (
    <div className="d-flex flex-column flex-shrink-0 p-3 text-white position-fixed" 
         style={{
           width: '220px',
           height: '100vh',
           background: 'linear-gradient(180deg, #2C3E50 0%, #1A252F 100%)',
           boxShadow: '2px 0 5px rgba(0,0,0,0.1)',
           top: 0,
           left: 0,
           zIndex: 1000,
           overflowY: 'auto'
         }}>
      <div className='d-flex align-items-center mb-4 px-2'>
        <span className='fs-4 fw-bold' style={{ letterSpacing: '1px' }}>@ Demo</span>
      </div>
      <hr className="bg-light" />
      <ul className='nav nav-pills flex-column gap-2'>
        <li>
          <NavLink 
            className={({ isActive }) => 
              `nav-link d-flex align-items-center gap-2 ${
                isActive ? 'active bg-white text-dark' : 'text-white'
              }`
            }
            to="/home"
            style={{
              borderRadius: '8px',
              transition: 'all 0.3s ease',
              padding: '10px 15px'
            }}
          >
            <FaHome /> Home
          </NavLink>
        </li>
        <li>
          <NavLink 
            className={({ isActive }) => 
              `nav-link d-flex align-items-center gap-2 ${
                isActive ? 'active bg-white text-dark' : 'text-white'
              }`
            }
            to='/vendors'
            style={{
              borderRadius: '8px',
              transition: 'all 0.3s ease',
              padding: '10px 15px'
            }}
          >
            <FaStore /> Vendors
          </NavLink>
        </li>
        <li>
          <NavLink 
            className={({ isActive }) => 
              `nav-link d-flex align-items-center gap-2 ${
                isActive ? 'active bg-white text-dark' : 'text-white'
              }`
            }
            to='/purchasing'
            style={{
              borderRadius: '8px',
              transition: 'all 0.3s ease',
              padding: '10px 15px'
            }}
          >
            <FaShoppingCart /> Purchasing
          </NavLink>
        </li>
        <li>
          <NavLink 
            className={({ isActive }) => 
              `nav-link d-flex align-items-center gap-2 ${
                isActive ? 'active bg-white text-dark' : 'text-white'
              }`
            }
            to='/invoices'
            style={{
              borderRadius: '8px',
              transition: 'all 0.3s ease',
              padding: '10px 15px'
            }}
          >
            <FaFileInvoiceDollar /> Invoices
          </NavLink>
        </li>
        <li>
          <NavLink 
            className={({ isActive }) => 
              `nav-link d-flex align-items-center gap-2 ${
                isActive ? 'active bg-white text-dark' : 'text-white'
              }`
            }
            to='/projects'
            style={{
              borderRadius: '8px',
              transition: 'all 0.3s ease',
              padding: '10px 15px'
            }}
          >
            <FaProjectDiagram /> Projects
          </NavLink>
        </li>
        <li>
          <NavLink 
            className={({ isActive }) => 
              `nav-link d-flex align-items-center gap-2 ${
                isActive ? 'active bg-white text-dark' : 'text-white'
              }`
            }
            to='/customers'
            style={{
              borderRadius: '8px',
              transition: 'all 0.3s ease',
              padding: '10px 15px'
            }}
          >
            <FaUserFriends /> Customers
          </NavLink>
        </li>
        <li>
          <NavLink 
            className={({ isActive }) => 
              `nav-link d-flex align-items-center gap-2 ${
                isActive ? 'active bg-white text-dark' : 'text-white'
              }`
            }
            to='/users'
            style={{
              borderRadius: '8px',
              transition: 'all 0.3s ease',
              padding: '10px 15px'
            }}
          >
            <FaUserCog /> Users
          </NavLink>
        </li>
      </ul>
      <div className="mt-auto">
        <hr className="bg-light" />
        <button 
          className="btn btn-link text-white text-decoration-none d-flex align-items-center gap-2 w-100"
          onClick={handleLogout}
          style={{
            padding: '10px 15px',
            transition: 'all 0.3s ease'
          }}
        >
          <FaSignOutAlt /> Logout
        </button>
      </div>
    </div>
  );
}

export default Sidebar;