import React from "react";

const Invoices = () => {
    return(
        <div className="container">
            <h2>Invoices</h2>
        </div>
    )
}

export default Invoices;