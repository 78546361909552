import React, { useEffect, useState } from "react";
import { clientUID } from "../clientUID";
import { CREATE_USER } from "../graphql/mutations";
import backendClient from "../BackendClient";
import { Button, Modal } from "react-bootstrap";
import { GET_USERS } from "../graphql/queries";
import { EDIT_USER } from "../graphql/mutations";
import { tableStyles } from "../stylsheet";
import { FaEdit, FaTrash } from "react-icons/fa";

const Users = () => {
    
    //data states
    const [ userData, setUserData ] = useState([]);
    const [ userUID, setUserUID ] = useState('');
    const [ firstName, setFirstName ] = useState('');
    const [ lastName, setLastName ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ show, setShow ] = useState(false);
    const [ showEdit, setShowEdit ] = useState(false);
    const handleClose = () => {
        setShow(false);
        setFirstName('');
        setLastName('');
        setEmail('');
    }
    const handleShow = () => setShow(true);

    const sessionId = localStorage.getItem('session_uid');
    
    //fetch user data
    const fetchUsers = async () => {
        try {
            const response = await fetch(backendClient, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'session-uid': sessionId,
                },
                body: JSON.stringify({
                    query: GET_USERS
                }),
            });

            const result = await response.json();
            console.log("Raw results: ", result)
            
            if (result.errors) {
                console.error('GraphQL Errors: ', result.errors);
            }

            if (!result.data) {
                console.error('No data returned from the server: ', result)
            }

            if(response.ok) {
                setUserData(result.data.users);
                console.log("User Data: ", result)
            }
        }

        catch (error) {
            console.error('Catastrophic error fetching user data: ', error)
        }
    }
    
    //handle save changes
    const handleSaveChanges = async () => {
        try {
            const response = await fetch(backendClient, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'session-uid': sessionId,
                },
                body: JSON.stringify({
                    query: CREATE_USER,
                    variables: {
                        email: email,
                        first_name: firstName,
                        last_name: lastName,
                        client_uid: clientUID,
                    },
                }),
            });

            const result = await response.json();

            if (response.ok) {
                console.log('User created successfully: ', result.data)

                handleClose();

                fetchUsers();
            }
        }
        
        catch (error) {
            console.error('Error creating client: ', error)
        }
    }

    useEffect(() => {
        fetchUsers();
    }, []);

    //handle edit user
    const handleEditUser = (user) => {
        setUserUID(user.uid);
        setFirstName(user.first_name);
        setLastName(user.last_name);
        setEmail(user.email);
        setShowEdit(true);
    }

    //handle close edit
    const handleCloseEdit = () => {
        setShowEdit(false);
        setFirstName('');
        setLastName('');
        setEmail('');
    }

    //handle save edit
    const handleSaveEdit = async () => {
        try {
            const response = await fetch(backendClient, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'session-uid': sessionId,
                },
                body: JSON.stringify({
                    query: EDIT_USER,
                    variables: {
                        user: {
                            uid: userUID,
                            email: email,
                            first_name: firstName,
                            last_name: lastName,
                        }
                    },
                }),
            });

            const result = await response.json();

            if (response.ok) {
                console.log('User updated successfully: ', result.data)

                handleCloseEdit();

                fetchUsers();
            }
        }
        
        catch (error) {
            console.error('Error updating user: ', error)
        }
    }

    //handle delete user
    const handleDeleteUser = async () => {
        console.log('Delete user')
    }

    

    return (
        <div className="container">
            <br></br>
            <h4>Create New User</h4>
            <br></br>
            <hr></hr>
            <br></br>
            <div className="d-flex">
                <input className="form-control" style={{ width: '350px' }} placeholder="Search..."></input>
                <button className="btn btn-dark ms-auto" type="button" onClick={handleShow}>
                    New User
                </button>
            </div>
            <br></br>
            <div style={tableStyles.container}>
                <table className="table table-hover">
                    <thead style={tableStyles.header}>
                        <tr>
                            <th style={tableStyles.headerCell}>First Name</th>
                            <th style={tableStyles.headerCell}>Last Name</th>
                            <th style={tableStyles.headerCell}>Email</th>
                            <th style={{ ...tableStyles.headerCell, width: '150px' }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            userData.map((user) => (
                                <tr key={user.uid} style={tableStyles.cell}>
                                    <td style={tableStyles.cell}>{user.first_name}</td>
                                    <td style={tableStyles.cell}>{user.last_name}</td>
                                    <td style={tableStyles.cell}>{user.email}</td>
                                    <td style={tableStyles.cell}>
                                        <div className="d-flex justify-content-center gap-2">
                                            <button
                                                className="btn btn-sm"
                                                onClick={() => handleEditUser(user)}
                                                style={tableStyles.actionButton}
                                            >
                                                <FaEdit /> Edit
                                            </button>
                                            <button
                                                className="btn btn-sm"
                                                style={tableStyles.actionButton}
                                                onClick={handleDeleteUser}
                                            >
                                                <FaTrash /> Delete
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
            <Modal show={show} onHide={handleClose} size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>Create New Project</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col">
                            <label htmlFor='firstname'>First Name</label>
                            <input
                                type="firstname"
                                className="form-control"
                                id='firstname'
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </div>
                        <div className="col">
                            <label htmlFor="lastname">Last Name</label>
                            <input
                                className="form-control"
                                id='firstname'
                                type="lastname"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </div>
                        <div className="col">
                            <label htmlFor="email">Email</label>
                            <input
                                className="form-control"
                                id="email"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="dark" onClick={handleSaveChanges}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* edit user modal */}
            <Modal show={showEdit} onHide={handleCloseEdit} size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>Edit User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col">
                            <label htmlFor='firstname'>First Name</label>
                            <input
                                type="firstname"
                                className="form-control"
                                id='firstname'
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </div>
                        <div className="col">
                            <label htmlFor="lastname">Last Name</label>
                            <input
                                className="form-control"
                                id='firstname'
                                type="lastname"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </div>
                        <div className="col">
                            <label htmlFor="email">Email</label>
                            <input
                                className="form-control"
                                id="email"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseEdit}>
                        Close
                    </Button>
                    <Button variant="dark" onClick={handleSaveEdit}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Users;