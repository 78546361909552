import React, { useState } from "react";
import { Modal, Button } from 'react-bootstrap';
import backendClient from "../BackendClient";
import { GET_CUSTOMERS } from "../graphql/queries";
import { useEffect } from "react";
import { CREATE_PROJECT_MUTATION, EDIT_PROJECT } from "../graphql/mutations";
import { GET_USERS } from "../graphql/queries";
import { GET_PROJECTS } from "../graphql/queries";
import { Link } from "react-router-dom";
import { FaEdit, FaTrash } from 'react-icons/fa';
import { tableStyles, pageMargin } from "../stylsheet";

const Projects = () => {

    //project data states
    const [ projectData, setProjectData ] = useState([]);
    
    //user data states
    const [ userData, setUserData ] = useState([]);
    const [ userUID, setUserUID ] = useState('');

    // modal states
    const [ show, setShow ] = useState(false);
    const handleClose = () => {
        setShow(false);
        setProjectId('');
        setProjectName('');
        setCustomerUID('');
        setStartDate('');
        setEndDate('');
    }
    const handleShow = () => setShow(true);

    //states for form fields
    const [ projectId, setProjectId ] = useState('');
    const [ projectName, setProjectName ] = useState('');
    const [ customerData, setCustomerData ] = useState([]);
    const [ customerUID, setCustomerUID ] = useState('');
    const [ startDate, setStartDate ] = useState('');
    const [ endDate, setEndDate ] = useState('');
    const [ error, setError ] = useState('');

    const sessionId = localStorage.getItem('session_uid');

    // Add edit project state variables
    const [showEditProject, setShowEditProject] = useState(false);
    const [editingProject, setEditingProject] = useState(null);

    // Add new state variables
    const [sortDirection, setSortDirection] = useState('asc');

    //fetch user data for loading in form select for project manager selection
    const fetchUsers = async () => {
        try {
            const response = await fetch(backendClient, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'session-uid': sessionId,
                },
                body: JSON.stringify({
                    query: GET_USERS,
                }),
            });

            const result = await response.json();
            console.log("Fetched users: ", result)

            if(response.ok) {
                setUserData(result.data.users)

            } else {
                console.log(result.error)
            }
        } 
        catch(error) {
            console.error("Error fetching user data: ", error)
        }
    };
    
    useEffect(() => {
        fetchUsers();
    }, []);
    
    //fetch customer data for loading in form select
    const fetchCustomers = async () => {
        try {
            const response = await fetch(backendClient, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'session-uid': localStorage.getItem('session_uid'),
                },
                body: JSON.stringify({
                    query: GET_CUSTOMERS,
                }),
            });

            const result = await response.json();
            console.log("Fetched customers: ", result)

            if (response.ok) {
                setCustomerData(result.data.customers);
                console.log("", result.data.customers);

            } else {
                setError(result.errors);
            }
        } catch (err) {
            setError(err.message);
        }
    };

    
    useEffect(() => {
        fetchCustomers();
    }, []);

    //fetch project data
    const fetchProject = async () => {
        try {
            const response = await fetch(backendClient, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'session-uid': localStorage.getItem('session_uid'),
                },
                body: JSON.stringify({
                    query: GET_PROJECTS,
                }),
            });

            const result = await response.json();
            console.log('Fetched project data: ', result)

            if (response.ok) {
                setProjectData(result.data.projects);

            } else {
                setError(result.errors);
            }
        } catch (err) {
            setError(err.message);
        }
    }

    useEffect(() => {
        fetchProject();
    }, []);

    //handle save changes
    const handleSaveChanges = async () => {
        try {
            const response = await fetch(backendClient, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'session-uid': sessionId,
                },
                body: JSON.stringify({
                    query: CREATE_PROJECT_MUTATION,
                    variables: {
                        name: projectName,
                        customer_uid: customerUID,
                        start_date: startDate,
                        end_date: endDate,
                        project_id: projectId,
                        project_manager_uid: userUID,
                    },
                }),
            });

            const result = await response.json();

            if (response.ok) {
                console.log('Project created successfully: ', result.data)

                handleClose();

                fetchProject();
            }
        }
        
        catch (error) {
            console.error('Error creating project (Catastrophic Failure): ', error)
        }
    }

    // Add handler functions
    const handleEdit = (project) => {
        setEditingProject(project);
        setShowEditProject(true);
        console.log('Edit project:', project);
    };

    const handleDelete = (uid) => {
        console.log('Delete project:', uid);
    };

    // Add handleEditClose function
    const handleEditClose = () => {
        setShowEditProject(false);
        setEditingProject(null);
    };

    // Add handleEditSave function
    const handleEditSave = async () => {
        try {
            const response = await fetch(backendClient, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'session-uid': sessionId,
                },
                body: JSON.stringify({
                    query: EDIT_PROJECT,
                    variables: {
                        project: {
                            uid: editingProject.uid,
                            name: editingProject.name,
                            project_id: editingProject.project_id,
                            customer_uid: editingProject.customer_uid,
                            project_manager_uid: editingProject.project_manager_uid,
                            start_date: editingProject.start_date,
                            end_date: editingProject.end_date,
                        },
                    },
                }),
            });

            const result = await response.json();

            if (response.ok) {
                console.log('Project updated successfully:', result.data);
                handleEditClose();
                fetchProject();
            } else {
                console.error('Failed to update project:', JSON.stringify(result.errors, null, 2));
            }
        } catch (error) {
            console.error('Failed to update project:', error.message);
        }
    };

    // Add sort handler
    const handleSort = () => {
        setSortDirection(current => current === 'asc' ? 'desc' : 'asc');
    };

    // Add sort function
    const getSortedProjects = () => {
        return [...projectData].sort((a, b) => {
            if (sortDirection === 'asc') {
                return a.project_id.localeCompare(b.project_id);
            } else {
                return b.project_id.localeCompare(a.project_id);
            }
        });
    };

    return(

        <div style={pageMargin.container}>
            <br></br>
            <h4>Project Master List</h4>
            <br></br>
            <hr></hr>
            <br></br>
            <div className="d-flex">
                <input className="form-control" style={{ width: '350px' }} placeholder="Search..."/>
                <button className="btn btn-dark ms-auto" type="button" onClick={handleShow}>
                    New Project
                </button>
            </div>
            <br></br>
            <div style={tableStyles.container}>
                <table className="table table-hover" style={tableStyles.table}>
                    <thead style={tableStyles.header}>
                        <tr>
                            <th 
                                onClick={handleSort} 
                                style={tableStyles.headerCell}
                            >
                                Project ID {sortDirection === 'asc' ? '↑' : '↓'}
                            </th>
                            <th style={tableStyles.headerCell}>Project Name</th>
                            <th style={tableStyles.headerCell}>Customer</th>
                            <th style={tableStyles.headerCell}>Start Date</th>
                            <th style={tableStyles.headerCell}>End Date</th>
                            <th style={tableStyles.headerCell}>Project Manager</th>
                            <th style={{...tableStyles.headerCell, width: "150px"}}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            getSortedProjects().map((project) => {
                                // Find the customer and project manager for each project
                                const customer = customerData.find(c => c.uid === project.customer_uid);
                                const customerName = customer ? customer.name : "Unknown Customer";
                                
                                const projectManager = userData.find(pm => pm.uid === project.project_manager_uid);
                                const projectManagerName = projectManager 
                                    ? `${projectManager.first_name} ${projectManager.last_name}`
                                    : `Unknown Project Manager: ${project.project_manager_uid}`;

                                return (
                                    <tr key={project.uid} style={tableStyles.row}>
                                        <td style={tableStyles.cell}>{project.project_id}</td>
                                        <td style={tableStyles.cell}>
                                            <Link 
                                                to={`/projects/${project.uid}`}
                                                style={{ color: 'inherit', textDecoration: 'underline' }}
                                            >
                                                {project.name}
                                            </Link>
                                        </td>
                                        <td style={tableStyles.cell}>{customerName}</td>
                                        <td style={tableStyles.cell}>{projectManagerName}</td>
                                        <td style={tableStyles.cell}>{project.start_date}</td>
                                        <td style={tableStyles.cell}>{project.end_date}</td>
                                        <td style={tableStyles.cell}>
                                            <div className="d-flex justify-content-center gap-2">
                                                <button
                                                    className="btn btn-sm"
                                                    onClick={() => handleEdit(project)}
                                                    style={tableStyles.actionButton}
                                                >
                                                    <FaEdit/> Edit
                                                </button>
                                                <button
                                                    className="btn btn-sm"
                                                    onClick={() => handleDelete(project.uid)}
                                                    style={tableStyles.actionButton}
                                                >
                                                    <FaTrash/> Delete
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
            </div>
            {/* Modal */}
            <Modal show={show} onHide={handleClose} size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>Create New Project</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col">
                            <label for='projectid'>Project ID</label>
                            <input 
                                type="projectid"
                                className="form-control" 
                                id='projectid'
                                value={projectId}
                                onChange={(e) => setProjectId(e.target.value)}
                            />
                        </div>
                        <div className="col">
                            <label htmlFor="projectname">Project Name</label>
                            <input 
                                className="form-control" 
                                id='projectname'
                                type="projectname"
                                value={projectName}
                                onChange={(e) => setProjectName(e.target.value)}
                            />
                        </div>
                        <div class="col">
                            <label for="clientselect">Customer Name</label>
                            <select class="form-control" id="clientselect" value={customerUID} onChange={(e) => setCustomerUID(e.target.value)}>
                                <option value="" disabled>Select Customer</option>
                                {customerData.map(customer => (
                                    <option key={customer.uid} value={customer.uid}>
                                        {customer.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label for='projectmanager'>Project Manager</label>
                            <select className="form-control" id="projectmanager" value={userUID} onChange={(e) => {setUserUID(e.target.value); console.log("Selected User ID: ", e.target.value)}}>
                                <option value="" disabled>Select Project Manager</option>
                                {
                                    userData.map(user => (
                                        <option key={user.uid} value={user.uid}>
                                            {`${user.first_name} ${user.last_name}`}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="col">
                            <label for='startdate'>Start Date</label>
                            <input 
                                className="form-control" 
                                id="startdate"
                                type="date"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                        </div>
                        <div className="col">
                            <label for='enddate'>End Date</label>
                            <input 
                                className="form-control" 
                                id="enddate"
                                type="date"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                        </div>
                    </div>                    
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="dark" onClick={handleSaveChanges}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal> 

            {/* Edit Modal */}
            <Modal show={showEditProject} onHide={handleEditClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Edit Project</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="editProjectName">Project Name</label>
                        <input
                            type="text"
                            className="form-control"
                            id="editProjectName"
                            value={editingProject?.name || ''}
                            onChange={(e) => setEditingProject({...editingProject, name: e.target.value})}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="editProjectId">Project ID</label>
                        <input
                            type="text"
                            className="form-control"
                            id="editProjectId"
                            value={editingProject?.project_id || ''}
                            onChange={(e) => setEditingProject({...editingProject, project_id: e.target.value})}
                        />
                    </div>
                    <div className="row mb-3">
                        <div className="col">
                            <label htmlFor="editStartDate">Start Date</label>
                            <input
                                type="date"
                                className="form-control"
                                id="editStartDate"
                                value={editingProject?.start_date || ''}
                                onChange={(e) => setEditingProject({...editingProject, start_date: e.target.value})}
                            />
                        </div>
                        <div className="col">
                            <label htmlFor="editEndDate">End Date</label>
                            <input
                                type="date"
                                className="form-control"
                                id="editEndDate"
                                value={editingProject?.end_date || ''}
                                onChange={(e) => setEditingProject({...editingProject, end_date: e.target.value})}
                            />
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="editCustomer">Customer</label>
                        <select
                            className="form-select"
                            id="editCustomer"
                            value={editingProject?.customer_uid || ''}
                            onChange={(e) => setEditingProject({...editingProject, customer_uid: e.target.value})}
                        >
                            <option value="">Select Customer</option>
                            {customerData.map(customer => (
                                <option key={customer.uid} value={customer.uid}>{customer.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="editProjectManager">Project Manager</label>
                        <select
                            className="form-select"
                            id="editProjectManager"
                            value={editingProject?.project_manager_uid || ''}
                            onChange={(e) => setEditingProject({...editingProject, project_manager_uid: e.target.value})}
                        >
                            <option value="">Select Project Manager</option>
                            {userData.map(user => (
                                <option key={user.uid} value={user.uid}>{`${user.first_name} ${user.last_name}`}</option>
                            ))}
                        </select>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleEditClose}>
                        Cancel
                    </Button>
                    <Button variant="dark" onClick={handleEditSave}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>   
    )
}

export default Projects;
