import React from 'react';
import { useLocation } from 'react-router-dom';
import Sidebar from './Components/Sidebar';

const Layout = ({ children }) => {
  const location = useLocation();

  // Don't show sidebar on the login page
  const hideSidebarOnRoutes = ["/"];

  return (
    <div>
      <div className="row">
        {/* Conditionally render sidebar based on the current route */}
        {!hideSidebarOnRoutes.includes(location.pathname) && (
          <div className="col-auto">
            <Sidebar />
          </div>
        )}
        
        {/* Main content */}
        <div className={!hideSidebarOnRoutes.includes(location.pathname) ? "col" : "col-12"}>
          <div className="p-4">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;