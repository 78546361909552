import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import backendClient from "../BackendClient";
import { Modal, Button } from "react-bootstrap";
import { GET_LCAT_BY_PROJECT_UID, GET_PHASES_BY_PROJECT_UID, GET_CUSTOMERS, GET_SINGLE_PROJECT, GET_USERS } from "../graphql/queries";
import { EDIT_LCAT, EDIT_PHASE } from "../graphql/mutations";
import { CREATE_PROJECT_PHASE, CREATE_LABOR_CATEGORY } from "../graphql/mutations";
import { Link } from "react-router-dom";
import { FaEdit, FaTrash } from 'react-icons/fa';
import { tableStyles, pageMargin } from "../stylsheet";


const ProjectDetail = () => {
   
    //#region fetched data states
    //retrieve project uid from url
    const { uid } = useParams();
    
    //project data use state
    const [ projectData , setProjectData ] = useState([]);

    //phase data use state
    const [ phaseData, setPhaseData ] = useState([]);

    //LCAT data use state
    const [ lcatData, setLcatData ] = useState([]);

    //Customer data use states
    const [ customerData, setCustomerData ] = useState([]);

    //User data use states
    const [ userData, setUserData ] = useState([]);
    //#endregion

    //#region read-only form states
    const [ phaseID, setPhaseID ] = useState('');
    const [ startDate, setStartDate ] = useState('');
    const [ endDate, setEndDate ] = useState('');
    const [ description, setDescription ] = useState('');
    const [ budget, setBudget ] = useState('');
    // #endregion
    
    //#region phase modal states
    const [ showPhase, setShowPhase ] = useState(false);
    const handleShowPhase = () => setShowPhase(true);
    const handleClosePhase = () => {
        setShowPhase(false);
    }
    //#endregion

    //#region LCAT modal states
    const [lcat, setLCAT] = useState('');
    const [ showLCAT, setShowLCAT ] = useState(false);
    const handleShowLCAT = () => setShowLCAT(true);
    const handleCloseLCAT = () => {
        setShowLCAT(false);
    }
    //#endregion

    //#region LCAT Edit modal states
    const [ showEditLCAT, setShowEditLCAT ] = useState(false);
    const [ selectedLCAT, setSelectedLCAT ] = useState({});
    const handleShowEditLCAT = () => setShowEditLCAT(true);
    const handleCloseEditLCAT = () => {
        setShowEditLCAT(false);
    }
    // #endregion

    //#region Phase edit modal states
    const [ showEditPhase, setShowEditPhase ] = useState(false);
    const [ selectedPhase, setSelectedPhase ] = useState({});
    const handleShowEditPhase = () => setShowEditPhase(true);
    const handleCloseEditPhase = () => {
        setShowEditPhase(false);
    }
    //#endregion

    //#region fetch lcat data by project uid
    const fetchLcatData = async () => {
        try {
            const response = await fetch (backendClient, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'session-uid': localStorage.getItem('session_uid'),
                },
                body: JSON.stringify({
                    query: GET_LCAT_BY_PROJECT_UID,
                    variables: {
                        filter: {
                            uids: uid
                        }
                    }
                }),
            });

            const result = await response.json();
            console.log("Project UID: ", uid)   
            console.log(JSON.stringify(result, null, 2));

            if(response.ok) {
                setLcatData(result.data.projects[0].labor_categories)
            }

        } catch (error) {
            console.log("Catastrophic error fetching lcat data: ", error)
        }
    }

    useEffect(() => {
        fetchLcatData()
    }, [])
    //#endregion

    //#region fetch phase level data by project uid
    const fetchPhaseData = async () => {
        try {
            const response = await fetch(backendClient, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'session-uid': localStorage.getItem('session_uid'),
                },
                body: JSON.stringify({
                    query: GET_PHASES_BY_PROJECT_UID,
                    variables: {
                        filter: {
                            uids: uid
                        }
                    }
                }),
            });

            const result = await response.json();

            if(response.ok) {
                setPhaseData(result.data.projects[0]?.phases);
            }
        } catch (error) {
            console.log("Catastrophic failure: ", error)
        }
    }

    useEffect(() => {
        fetchPhaseData();
    }, [])
    //#endregion

    //#region fetch project level data by project uid
    const fetchProjectDetails = async () => {
        try {
            const response = await fetch(backendClient, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'session-uid': localStorage.getItem('session_uid'),
                },
                body: JSON.stringify({
                    query: GET_SINGLE_PROJECT,
                    variables: {
                        filter: {
                            uids: uid
                        }
                    }
                })
            });

            const result = await response.json();

            if(response.ok) {
                setProjectData(result.data.projects[0])
            }
        } catch (error) {
            console.error("Catastrophic error fetching project data: ", error)
        }
    };

    //load project data when component mounts
    useEffect(() => {
        fetchProjectDetails();
    }, [])
    //#endregion

    //#region fetch customer data
    const fetchCustomers = async () => {
        try {
            const response = await fetch(backendClient, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'session-uid': localStorage.getItem('session_uid'),
                },
                body: JSON.stringify({
                    query: GET_CUSTOMERS,
                }),
            });

            const result = await response.json();

            if (response.ok) {
                setCustomerData(result.data.customers);
            }
        } catch (error) {
            console.error("Error fetching customer data: ", error)
        }
    };

    
    useEffect(() => {
        fetchCustomers();
    }, []);
    //#endregion

    //#region fetch user data
    const fetchUsers = async () => {
        try {
            const response = await fetch(backendClient, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'session-uid': localStorage.getItem("session_uid"),
                },
                body: JSON.stringify({
                    query: GET_USERS,
                }),
            });

            const result = await response.json();
            

            if(response.ok) {
                setUserData(result.data.users)
            }
        } 
        catch(error) {
            console.error("Error fetching user data: ", error)
        }
    };
    
    useEffect(() => {
        fetchUsers();
    }, []);
    //#endregion
    
    //#region create new labor category
    const handleSaveLCAT = async () => {
        try {
            const response = await fetch(backendClient, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'session-uid': localStorage.getItem('session_uid')
                },
                body: JSON.stringify({
                    query: CREATE_LABOR_CATEGORY,
                    variables: {
                        lcat: {
                            project_uid: uid,
                            description: lcat
                        }
                    }
                }),
            });

            const result = await response.json();

            if(response.ok) {
                console.log('LCAT successfully created: ', result.data)
                handleCloseLCAT();
                fetchLcatData();
            }
        }
        catch (error) {
            console.log("Catastrophic error saving data: ", error)
        }
    }
   
    //#endregion

    //#region save changes when creating new phase
    const handleSavePhase = async () => {
        try {
            const response = await fetch(backendClient, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'session-uid': localStorage.getItem('session_uid')
                },
                body: JSON.stringify({
                    query: CREATE_PROJECT_PHASE,
                    variables: {
                        phase: {
                            project_uid: uid,
                            description: description,
                            start_date: startDate,
                            end_date: endDate,
                            budget: budget,
                            phase_id: phaseID,
                        }
                    },
                }),
            });

            const result = await response.json();

            if(response.ok) {
                console.log('Phase successfully created: ', result.data)
                handleClosePhase();
                fetchPhaseData();
            }
        } catch (error) {
            console.log("Catastrophic error saving data: ", error)
        }
    }
    //#endregion

    //#region save changes when editing labor category
    const handleSaveEditLCAT = async () => {
        try {
            const response = await fetch(backendClient, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'session-uid': localStorage.getItem('session_uid')
                },
                body: JSON.stringify({
                    query: EDIT_LCAT,
                    variables: {
                        lcat: {
                            uid: selectedLCAT.uid,
                            description: selectedLCAT.description
                        }
                    }
                }),
            });

            const result = await response.json();

            if(response.ok) {
                console.log('LCAT successfully edited: ', result.data)
                handleCloseEditLCAT();
                fetchLcatData();
            }
        } catch (error) {
            console.log("Catastrophic error saving data: ", error)
        }
        console.log("Save LCAT: ", selectedLCAT)
    }
    //#endregion

    //#region find the customer and project manager for this single project
    const customer = customerData.find(c => c.uid === projectData.customer_uid);
    const customerName = customer ? customer.name : "Unknown Customer";

    const projectManager = userData.find(pm => pm.uid === projectData.project_manager_uid);
    const projectManagerName = projectManager ? `${projectManager.first_name} ${projectManager.last_name}` : `Unknown PM: ${projectData.project_manager_uid}`
    //#endregion
    
    //#region edit and delete lcat functions
    const handleEditLCAT = (lcat) => {
        setSelectedLCAT(lcat);
        setShowEditLCAT(true);
        console.log("Edit LCAT: ", lcat)
    }

    const handleDeleteLCAT = (uid) => {
        console.log("Delete LCAT: ", uid)
    }
    //#endregion

    //#region edit and delete phase functions
    const handleEditPhase = (phase) => {
        setShowEditPhase(true);
        setSelectedPhase(phase);
        console.log("Edit Phase: ", phase)
    }

    const handleSaveEditPhase = async () => {
        try {
            const response = await fetch(backendClient, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'session-uid': localStorage.getItem('session_uid')
                },
                body: JSON.stringify({
                    query: EDIT_PHASE,
                    variables: {
                        phase: {
                            uid: selectedPhase.uid,
                            description: selectedPhase.description,
                            start_date: selectedPhase.start_date,
                            end_date: selectedPhase.end_date,
                            budget: selectedPhase.budget,
                            phase_id: selectedPhase.phase_id
                        }
                    }
                }),
            });

            const result = await response.json();

            if(response.ok) {
                console.log('Phase successfully edited: ', result.data)
                handleCloseEditPhase();
                fetchPhaseData();
            }
        } catch (error) {
            console.log("Catastrophic error saving data: ", error)
        }
    }

    const handleDeletePhase = (uid) => {
        console.log("Delete Phase: ", uid)
    }
    //#endregion

    // Add sorting state variables
    const [lcatSortDirection, setLcatSortDirection] = useState('asc');
    const [phaseSortDirection, setPhaseSortDirection] = useState('asc');

    // Add sort handlers
    const handleLcatSort = () => {
        setLcatSortDirection(current => current === 'asc' ? 'desc' : 'asc');
    };

    const handlePhaseSort = () => {
        setPhaseSortDirection(current => current === 'asc' ? 'desc' : 'asc');
    };

    // Add sort functions
    const getSortedLcats = () => {
        return [...lcatData].sort((a, b) => {
            if (lcatSortDirection === 'asc') {
                return a.description.localeCompare(b.description);
            } else {
                return b.description.localeCompare(a.description);
            }
        });
    };

    const getSortedPhases = () => {
        return [...phaseData].sort((a, b) => {
            if (phaseSortDirection === 'asc') {
                return a.phase_id.localeCompare(b.phase_id);
            } else {
                return b.phase_id.localeCompare(a.phase_id);
            }
        });
    };

    return (
        <div style={pageMargin.container}>
            <h4>Project Detail Page</h4>
            <br></br>
            <h5>Project Info</h5>
            <br/>
            <div className="row">
                <div className="col">
                    <label htmlFor="projectid">Project ID</label>
                    <input className="form-control" id="projectid" readOnly value={projectData.project_id || ''}></input>
                </div>
                <div className="col">
                    <label htmlFor="projectname">Project Name</label>
                    <input className="form-control" id="projectname" readOnly value={projectData.name || ''}></input>
                </div>
                <div className="col">
                    <label htmlFor="customername">Customer Name</label>
                    <input className="form-control" id="customername" readOnly value={customerName || ''}></input>
                </div>
            </div>
            <br></br>
            <div className="row">
                <div className="col">
                    <label htmlFor="projectmanager">Project Manager</label>
                    <input className="form-control" id="projectmanager" readOnly value={projectManagerName || ''}></input>
                </div>
                <div className="col">
                    <label htmlFor="startdate">Start Date</label>
                    <input className="form-control" id="startdate" readOnly value={projectData.start_date || ''}></input>
                </div>
                <div className="col">
                    <label htmlFor="enddate">End Date</label>
                    <input className="form-control" id="enddate" readOnly value={projectData.end_date || ''}></input>
                </div>
            </div>
            <br/>
            <hr/>
            <br/>
            <h5>Labor Categories</h5>
            <br/>
            <div style={tableStyles.container}>
                <table className="table table-hover" style={tableStyles.table}>
                    <thead style={tableStyles.header}>
                        <tr>
                            <th 
                                onClick={handleLcatSort} 
                                style={tableStyles.headerCell}
                            >
                                Labor Category {lcatSortDirection === 'asc' ? '↑' : '↓'}
                            </th>
                            <th style={tableStyles.headerCell}>NTE Value</th>
                            <th style={tableStyles.headerCell}>Funded Value</th>
                            <th style={tableStyles.headerCell}>Act. Spend</th>
                            <th style={tableStyles.headerCell}>Fct. Spend</th>
                            <th style={tableStyles.headerCell}>LCAT UID</th>
                            <th className="text-center" style={{...tableStyles.headerCell, width: '150px'}}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {getSortedLcats().map((lcat) => (
                            <tr key={lcat.uid} style={tableStyles.cell}>
                                <td style={tableStyles.cell}>{lcat.description}</td>
                                <td style={tableStyles.cell}></td>
                                <td style={tableStyles.cell}></td>
                                <td style={tableStyles.cell}></td>
                                <td style={tableStyles.cell}></td>
                                <td style={tableStyles.cell}>{lcat.uid}</td>
                                <td style={tableStyles.cell}>
                                    <div className="d-flex justify-content-center gap-2">
                                        <button 
                                            className="btn btn-sm"
                                            onClick={() => handleEditLCAT(lcat)}
                                            style={tableStyles.actionButton}
                                        >
                                            <FaEdit/> Edit
                                        </button>
                                        <button 
                                            className="btn btn-sm"
                                            onClick={() => handleDeleteLCAT(lcat.uid)}
                                            style={tableStyles.actionButton}
                                        >
                                            <FaTrash/> Delete
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <br></br>
            <br></br>
            <div className="d-flex">
                <button className="btn btn-dark ms-auto" type="button" onClick={handleShowLCAT}>
                    New LCAT
                </button>
            </div>
            <br></br>
            <hr/>
            <br/>
            <h5>Phases</h5>
            <br/>
            <div style={tableStyles.container}>
                <table className="table table-hover" style={tableStyles.table}>
                    <thead style={tableStyles.header}>
                        <tr>
                            <th 
                                onClick={handlePhaseSort} 
                                style={tableStyles.headerCell}
                            >
                                Phase ID {phaseSortDirection === 'asc' ? '↑' : '↓'}
                            </th>
                            <th style={tableStyles.headerCell}>Description</th>
                            <th style={tableStyles.headerCell}>Start Date</th>
                            <th style={tableStyles.headerCell}>End Date</th>
                            <th style={tableStyles.headerCell}>NTE Value</th>
                            <th style={tableStyles.headerCell}>Funded Value</th>
                            <th style={tableStyles.headerCell}>Act. Spend</th>
                            <th style={tableStyles.headerCell}>Fct. Spend</th>
                            <th className="text-center" style={{...tableStyles.headerCell, width: '150px'}}></th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                            getSortedPhases().map((phase) => (
                                <tr key={phase.uid}>
                                    <td style={tableStyles.cell}>{phase.phase_id}</td>
                                    <td style={tableStyles.cell}>
                                        <Link to={`/projects/${uid}/phase/${phase.uid}`} className="text-dark text-decoration-underline">
                                            {phase.description}
                                        </Link>
                                    </td>
                                    <td style={tableStyles.cell}>{phase.start_date}</td>
                                    <td style={tableStyles.cell}>{phase.end_date}</td>
                                    <td style={tableStyles.cell}>{phase.budget}</td>
                                    <td style={tableStyles.cell}></td>
                                    <td style={tableStyles.cell}></td>
                                    <td style={tableStyles.cell}></td>
                                    <td style={tableStyles.cell}>
                                        <div className="d-flex justify-content-center gap-2">
                                            <button 
                                                className="btn btn-sm"
                                                onClick={() => handleEditPhase(phase)}
                                                style={tableStyles.actionButton}
                                            >
                                                <FaEdit/> Edit
                                            </button>
                                            <button 
                                                className="btn btn-sm"
                                                onClick={() => handleDeletePhase(phase.uid)}
                                                style={tableStyles.actionButton}
                                            >
                                                <FaTrash/> Delete
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))
                    }
                    </tbody>
                </table>
            </div>
            <br/>
            <br/>
            <div className="d-flex">
                <button className="btn btn-dark ms-auto" type="button" onClick={handleShowPhase}>
                    New Phase
                </button>
            </div>
            
            {/*  Modal for adding new phase */}
            <Modal show={showPhase} onHide={handleClosePhase} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Create New Phase</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col">
                            <label htmlFor="phaseid">Phase ID</label>
                            <input className="form-control" id="phaseid" value={phaseID} onChange={(e) => setPhaseID(e.target.value)}></input>
                        </div>
                        <div className="col">
                            <label htmlFor="description">Description</label>
                            <input className="form-control" id="description" value={description} onChange={(e) => setDescription(e.target.value)}></input>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label htmlFor="startdate">Start Date</label>
                            <input className="form-control" id="startdate" type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)}></input>
                        </div>
                        <div className="col">
                            <label htmlFor="enddate">End Date</label>
                            <input className="form-control" id="enddate" type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)}></input>
                        </div>
                        <div className="col">
                            <label htmlFor="budget">Budgeted Value</label>
                            <input className="form-control" id="budget" value={budget} onChange={(e) => setBudget(e.target.value)}></input>
                        </div>
                    </div>
                    <br/>
                    <br/>
                    <div className="d-flex">
                        <button className="btn btn-dark ms-auto" type="button" onClick={handleSavePhase}>
                            Create
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        

            {/* Modal for adding new labor category */}
            <Modal show={showLCAT} onHide={handleCloseLCAT} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Create New Labor Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col">
                            <label htmlFor="lcat">Labor Category</label>
                            <input className="form-control" id="lcat" value={lcat} onChange={(e) => (setLCAT(e.target.value))}></input>
                        </div>
                    </div>
                    <br/>
                    <br/>
                    <div className="d-flex">
                        <button className="btn btn-dark ms-auto" type="button" onClick={handleSaveLCAT}>
                            Save Changes
                        </button>
                    </div>
                </Modal.Body>
            </Modal>

            {/* Modal for editing existing labor category */}
            <Modal show={showEditLCAT} onHide={handleCloseEditLCAT} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Edit Labor Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col">
                            <label htmlFor="lcat">Labor Category</label>
                            <input
                                className="form-control"
                                id="lcat"
                                value={selectedLCAT?.description}
                                onChange={(e) => setSelectedLCAT({ ...selectedLCAT, description: e.target.value})}
                            >
                            </input>
                        </div>
                    </div>
                    <br/>
                    <br/>
                    <div className="d-flex">
                        <button className="btn btn-dark ms-auto" type="button" onClick={handleSaveEditLCAT}>
                            Save Changes
                        </button>
                    </div>
                </Modal.Body>
            </Modal>    

            {/* Modal for editing existing phase */}
            <Modal show={showEditPhase} onHide={handleCloseEditPhase} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Edit Phase</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col">
                            <label htmlFor="phaseid">Phase ID</label>
                            <input className="form-control" id="phaseid" value={selectedPhase?.phase_id} onChange={(e) => setSelectedPhase({ ...selectedPhase, phase_id: e.target.value})}></input>
                        </div>
                        <div className="col">
                            <label htmlFor="description">Description</label>
                            <input className="form-control" id="description" value={selectedPhase?.description} onChange={(e) => setSelectedPhase({ ...selectedPhase, description: e.target.value})}></input>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label htmlFor="startdate">Start Date</label>
                            <input className="form-control" id="startdate" type="date" value={selectedPhase?.start_date} onChange={(e) => setSelectedPhase({ ...selectedPhase, start_date: e.target.value})}></input>
                        </div>
                        <div className="col">
                            <label htmlFor="enddate">End Date</label>
                            <input className="form-control" id="enddate" type="date" value={selectedPhase?.end_date} onChange={(e) => setSelectedPhase({ ...selectedPhase, end_date: e.target.value})}></input>
                        </div>
                        <div className="col">
                            <label htmlFor="budget">Budgeted Value</label>
                            <input className="form-control" id="budget" value={selectedPhase?.budget} onChange={(e) => setSelectedPhase({ ...selectedPhase, budget: e.target.value})}></input>
                        </div>
                    </div>
                    <br/>
                    <br/>
                    <div className="d-flex">
                        <button className="btn btn-dark ms-auto" type="button" onClick={handleSaveEditPhase}>
                            Save Changes
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ProjectDetail;